import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { recommendedCars } from "../data/cars";

export interface RecommendedCarsProps {
    rentalSliderOptions: any;
    selectedItems: { [key: number]: boolean };
    handleItemClick: (id: number) => void;
}

const RecommendedCars: React.FC<RecommendedCarsProps> = ({
    rentalSliderOptions,
    selectedItems,
    handleItemClick,
}) => {    

    return (
        <section className="section popular-services">
            <div className="container">
                <div className="section-heading" data-aos="fade-down">
                    <h2>Listado de planes Recomendados</h2>
                    <p>Explora las mejores opciones de planes de ahorro que se ajustan a tus necesidades.</p>
                </div>
                <div className="row">
                    <div className="popular-slider-group">
                        <div className="rental-deal-slider">
                            <Slider {...rentalSliderOptions} className="rental-slider">
                                {recommendedCars.map((car) => (
                                    <div className="rental-car-item" key={car.id}>
                                        <div className="listing-item mb-0">
                                            <div className="listing-img">
                                                <Link to="/listing-details">
                                                    <img
                                                        src={car.image}
                                                        className="img-fluid"
                                                        alt={car.name}
                                                    />
                                                </Link>
                                                <div
                                                    className="fav-item justify-content-end"
                                                    onClick={() => handleItemClick(car.id)}
                                                >
                                                    <Link
                                                        to="#"
                                                        className={`fav-icon ${
                                                            selectedItems[car.id] ? "selected" : ""
                                                        }`}
                                                    >
                                                        <i className="feather icon-heart" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="listing-content">
                                                <div className="listing-features">
                                                    <div className="fav-item-rental">
                                                        <div className="featured-text">
                                                            ${car.price}
                                                            <span>/cuota</span>
                                                        </div>
                                                    </div>
                                                    <div className="list-rating">
                                                        {[...Array(Math.floor(car.rating))].map((_, index) => (
                                                            <i
                                                                key={index}
                                                                className="fas fa-star filled"
                                                            />
                                                        ))}
                                                        {car.rating % 1 !== 0 && (
                                                            <i className="fas fa-star-half-alt filled" />
                                                        )}
                                                        <span>({car.rating})</span>
                                                    </div>
                                                    <h3 className="listing-title">
                                                        <Link to="/listing-details">{car.name}</Link>
                                                    </h3>
                                                </div>
                                                {/* <div className="listing-details-group">
                                                    <ul>
                                                        {car.features.map((feature, index) => (
                                                            <li key={index}>
                                                                <span>
                                                                    <img
                                                                        src={`assets/img/icons/${feature}.svg`}
                                                                        alt={feature}
                                                                    />
                                                                </span>
                                                                <p>{feature}</p>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div> */}
                                                <div className="listing-button">
                                                    <Link to="/listing-details" className="btn btn-order">
                                                        <span>
                                                            <i className="feather icon-calendar me-2" />
                                                        </span>
                                                        Ver Detalles
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="view-all text-center" data-aos="fade-down">
                    <Link
                        to="/listing-grid"
                        className="btn btn-view d-inline-flex align-items-center"
                    >
                        Ver Todos los Planes{" "}
                        <span>
                            <i className="feather icon-arrow-right ms-2" />
                        </span>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default RecommendedCars;