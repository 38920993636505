import { Link } from "react-router-dom";
import { BusinessInformation } from "../../../types/types";
import { businessData } from "../data/us";

const FooterContact = () => {
    const businessInfo : BusinessInformation = businessData;

  return (
    <div className="footer-contact footer-widget">
      <h5 className="footer-title">Información de Contacto</h5>
      <div className="footer-contact-info">
        <div className="footer-address">
          <span>
            <i className="feather icon-phone-call" />
          </span>
          <div className="addr-info">
            <Link to={`tel:${businessInfo.phone}`}>{businessInfo.phone}</Link>
          </div>
        </div>
        <div className="footer-address">
          <span>
            <i className="feather icon-mail" />
          </span>
          <div className="addr-info">
            <Link to={`mailto:${businessInfo.email}`}>{businessInfo.email}</Link>
          </div>
        </div>
        <div className="footer-social-widget">
          <ul className="nav-social">
            <li>
              <Link to={`https://x.com/${businessInfo.twitter}`}>
                <i className="fab fa-twitter fi-icon" />
              </Link>
            </li>
            <li>
              <Link to={`https://www.instagram.com/${businessInfo.instagram}`}>
                <i className="fab fa-instagram fi-icon" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterContact;