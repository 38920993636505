import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBusinessInformationById, sendContactMessage } from "../../api/cuotaplan-api";
import { getMediaUrl } from "../../api/wordpress/posts";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { registerGAEvent } from "../../google";
import { setPageTitleAndRegisterGAEvent } from "../../utils";
import Breadcrumbs from "../common/breadcrumbs";
import Header from "../common/header";

const Contact = () => {
  const [businessInfo, setBusinessInfo] = useState<any>(null);
  const [imageUrl, setIsImageUrl] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    nombre: "", // Cambiado de name a nombre
    email: "",
    tipoContacto: "", // Nuevo campo agregado
    asunto: "", // Nuevo campo agregado
    mensaje: "", // Cambiado de message a mensaje
});
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setPageTitleAndRegisterGAEvent("Contacto - Cuotaplan", "Página", "Visita");
    AOS.init({ duration: 1200, once: true });

    const fetchBusinessInfo = async () => {
      try {
        const data = await getBusinessInformationById(1); // ID del negocio es 1
        setBusinessInfo(data);
      } catch (error) {
        console.error("Error fetching business information:", error);
      }
      // Actualizar el título de la página
      setPageTitleAndRegisterGAEvent("Contacto - Cuotaplan", "Página", "Visita",1);
    };

    const fetchImage = async () => {
      try {
        const imageUrl = await getMediaUrl("contact-info");
        setIsImageUrl(imageUrl);
      } catch (error) {
        console.error("Error fetching image:", error);
        setIsImageUrl(null);
      }
    };    
    fetchBusinessInfo();
    fetchImage();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccessMessage(null);
    setErrorMessage(null);

    try {
        await sendContactMessage(formData); // Usa la función de cuotaplan-api.ts
        setSuccessMessage("Tu mensaje se ha enviado correctamente.");        
        // Registrar evento de Google Analytics
        registerGAEvent('Pagina','Envio de contacto','Formulario de Contacto',1);

        // Limpiar formulario
        setFormData({
            nombre: "",
            email: "",
            tipoContacto: "",
            asunto: "",
            mensaje: ""
        });
    } catch (error) {
        setErrorMessage("Hubo un error al enviar el mensaje. Intenta de nuevo.");
        console.error("Error sending contact message:", error);
    }
};

  return (
    <div className="main-wrapper">
      <Header />
      <Breadcrumbs title="Contáctanos" subtitle="Páginas" />
      <section className="contact-section">
        <div className="container">
          {/* Información de Contacto */}
          <div className="contact-info-area">
            <div className="row">
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
                data-aos-duration={1200}
                data-aos-delay="0.1"
              >
                <div className="single-contact-info flex-fill">
                  <span>
                    <i className="feather icon-phone" />
                  </span>
                  <h3>Teléfono</h3>
                  <Link to={`tel:${businessInfo?.phone || ""}`}>
                    {businessInfo?.phone || "No disponible"}
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
                data-aos-duration={1200}
                data-aos-delay="0.2"
              >
                <div className="single-contact-info flex-fill">
                  <span>
                    <i className="feather icon-mail" />
                  </span>
                  <h3>Email</h3>
                  <Link to={`mailto:${businessInfo?.email || ""}`}>
                    {businessInfo?.email || "No disponible"}
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
                data-aos-duration={1200}
                data-aos-delay="0.3"
              >
                <div className="single-contact-info flex-fill">
                  <span>
                    <i className="feather icon-map-pin" />
                  </span>
                  <h3>Ubicación</h3>
                  <p>
                    {businessInfo?.geo_localidad?.nombre || "Localidad no disponible"}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
                data-aos-duration={1200}
                data-aos-delay="0.4"
              >
                <div className="single-contact-info flex-fill">
                  <span>
                    <i className="feather icon-clock" />
                  </span>
                  <h3>Horario</h3>
                  <p>
                    {businessInfo?.opening_hours?.Monday || "Horario no disponible"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Formulario */}
          <div
            className="form-info-area"
            data-aos="fade-down"
            data-aos-duration={1200}
            data-aos-delay="0.5"
          >
            <div className="row">
              <div className="col-lg-6 d-flex">
                <ImageWithBasePath
                  src={imageUrl || ""}
                  className="img-fluid"
                  alt="Contact"
                />
              </div>
              <div className="col-lg-6">
                <form onSubmit={handleFormSubmit}>
                  <div className="row">
                    <h1>¡Contáctanos!</h1>
                    {successMessage && <p className="text-success">{successMessage}</p>}
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                    <div className="col-md-12">
                        <div className="input-block">
                            <label>
                                Nombre <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="nombre"
                                className="form-control"
                                value={formData.nombre}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="input-block">
                            <label>
                                Correo Electrónico <span className="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                name="email"
                                className="form-control"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="input-block">
                            <label>
                                Tipo de Contacto <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="tipoContacto"
                                className="form-control"
                                value={formData.tipoContacto}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="input-block">
                            <label>
                                Asunto <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="asunto"
                                className="form-control"
                                value={formData.asunto}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="input-block">
                            <label>
                                Mensaje <span className="text-danger">*</span>
                            </label>
                            <textarea
                                name="mensaje"
                                className="form-control"
                                rows={4}
                                value={formData.mensaje}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                  </div>
                  <button className="btn contact-btn">Enviar Consulta</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;