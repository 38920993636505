import { useEffect, useState } from "react";
import Slider from "react-slick";
import { getMediaByNameSize } from "../../../api/wordpress/posts";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { DetailProductProps } from "../../../types/cars-types";
import { getSliderSettings } from "../data/sliderSettings";

const DetailProduct: React.FC<DetailProductProps> = ({ plan }) => {

    if (!plan || !plan.planProgram) {
        return null; 
    }
    const [currentIndex, setCurrentIndex] = useState(0);
    const [logoSrc, setLogoSrc] = useState<string>("");
    const [open, setOpen] = useState(false);

    const openLightbox = (index: number) => {
        setCurrentIndex(index);
        setOpen(true);
    };
    useEffect(() => {
        if (plan.planProgram.logo) {
          getMediaByNameSize(plan.planProgram.logo).then((mediaItems) => {
            if (mediaItems.length > 0) {
              console.log(mediaItems[0].source_url);
              setLogoSrc(mediaItems[0].source_url);
            }
          });
        }
      }, [plan.planProgram.logo]);
    
    return (
        <div className="detail-product">
            <div className="pro-info">
                {/* <div className="pro-badge">
                    <span className="badge-km">
                        <i className="fa-solid fa-person-walking" />
                        4.2 Km de distancia
                    </span>
                            src={logoSrc}
                        <i className="fa-regular fa-heart" />
                    </Link>
                </div> */}
                <ul>
                    {logoSrc &&
                    <li className="del-airport">
                        <i className="fa-solid fa-check" />
                        
                        <ImageWithBasePath
                            src={logoSrc}
                            alt={plan.planProgram.name}
                        />
                    </li>}
                    {/* <li className="del-home">
                        <i className="fa-solid fa-check" />
                        Entrega a domicilio
                    </li> */}
                </ul>
            </div>
            <div className="slider detail-bigimg">
                <Slider {...getSliderSettings(1)}>
                    <div className="product-img">
                        <ImageWithBasePath
                            src="assets/img/cars/slider-01.jpg"
                            alt="Imagen del producto"
                        />
                    </div>
                    <div className="product-img">
                        <ImageWithBasePath
                            src="assets/img/cars/slider-02.jpg"
                            alt="Imagen del producto"
                        />
                    </div>
                    <div className="product-img">
                        <ImageWithBasePath
                            src="assets/img/cars/slider-03.jpg"
                            alt="Imagen del producto"
                        />
                    </div>
                    <div className="product-img">
                        <ImageWithBasePath
                            src="assets/img/cars/slider-04.jpg"
                            alt="Imagen del producto"
                        />
                    </div>
                    <div className="product-img">
                        <ImageWithBasePath
                            src="assets/img/cars/slider-05.jpg"
                            alt="Imagen del producto"
                        />
                    </div>
                </Slider>
            </div>
            <div className="slider slider-nav-thumbnails">
                <Slider {...getSliderSettings(3)}>
                    <div>
                        <ImageWithBasePath
                            src="assets/img/cars/slider-thum-01.jpg"
                            alt="Miniatura del producto"
                        />
                    </div>
                    <div>
                        <ImageWithBasePath
                            src="assets/img/cars/slider-thum-02.jpg"
                            alt="Miniatura del producto"
                        />
                    </div>
                    <div>
                        <ImageWithBasePath
                            src="assets/img/cars/slider-thum-03.jpg"
                            alt="Miniatura del producto"
                        />
                    </div>
                    <div>
                        <ImageWithBasePath
                            src="assets/img/cars/slider-thum-04.jpg"
                            alt="Miniatura del producto"
                        />
                    </div>
                    <div>
                        <ImageWithBasePath
                            src="assets/img/cars/slider-thum-05.jpg"
                            alt="Miniatura del producto"
                        />
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default DetailProduct;