import "rc-slider/assets/index.css";
import { useCallback, useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getPlans } from "../../api/vehicles/cars";
import CarGridView from "../../components/cars/CarGridView";
import { brands, categories } from "../../components/cars/data/cars";
import { HeartProps, initialHearts } from "../../components/cars/data/hearts";
import SortByPlans from "../../components/cars/home/SortByPlans";
import VehicleSearchFilters from "../../components/cars/home/VehicleSearchFilters";
import { Plan } from "../../types/cars-types";
import { setPageTitleAndRegisterGAEvent } from "../../utils";
import Breadcrumbs from "../common/breadcrumbs";
import { all_routes } from "../router/all_routes";

const ListingGrid = () => {
    const [selectedModel, setSelectedModel] = useState<string | null>(null);
    const [selectedBrand, setSelectedBrand] = useState<{ id: number; name: string } | null>(null);
    const [priceRange, setPriceRange] = useState<[number, number]>([1, 1000000000]);
    const [isNew, setIsNew] = useState<boolean>(false);
    const [location, setLocation] = useState<string>("");
    const routes = all_routes;
    const [selectedNumber, setSelectedNumber] = useState(null);
    const [selectedSort, setSelectedSort] = useState(null);
    const [activeHearts, setActiveHearts] = useState<HeartProps>(initialHearts);

    const toggleLike = (key: keyof HeartProps) => {
        setActiveHearts((prevState: HeartProps) => ({ ...prevState, [key]: !prevState[key] }));
    };

    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedRatings, setSelectedRatings] = useState<number[]>([]);
    const [selectedRecommendations, setSelectedRecommendations] = useState<string[]>([]);
    const [cars, setCars] = useState<Plan[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const fetchCars = useCallback(async () => {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const params = {
                model: queryParams.get("model"),
                brand: queryParams.get("brand"),
                minPrice: queryParams.get("minPrice"),
                maxPrice: queryParams.get("maxPrice"),
                condition: queryParams.get("condition"),
                location: queryParams.get("location"),
            };

            const response: Plan[] = await getPlans(params);
            const plansData = response.map((plan) => ({
                ...plan,
                title: plan.name,
                brand: plan.vehicle.brand,
                price: plan.vehicle.price,
                location: plan.dealer.fullAddress,
                features: plan.vehicle.features,
            }));

            setCars(plansData);
            console.log("Datos del plan: ", plansData);
        } catch (error) {
            console.error("Error fetching cars:", error);
        }
    }, []);

    useEffect(() => {
        setPageTitleAndRegisterGAEvent("Listado de planes", "Página", "Visita", 1);
        // registerGAEvent("Página", "Visita", "Listado de planes", 1);
        fetchCars(); // Aseguramos que solo se llame una vez
    }, [fetchCars]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

  return (
    <div className="listing-page">
      <Breadcrumbs title="Listado de planes" subtitle="Planes" />
      {/* Search */}
      <div className="section-search page-search">
        <VehicleSearchFilters
          type="vehicles"
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          priceRange={{ min: priceRange[0], max: priceRange[1] }}
          setPriceRange={({ min, max }) => setPriceRange([min, max])}
          isNew={isNew}
          setIsNew={setIsNew}
          location={location}
          setLocation={setLocation}
        />
      </div>
      {/* /Search */}
      {/* Sort By */}
      <SortByPlans
        selectedNumber={selectedNumber}
        setSelectedNumber={setSelectedNumber}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
      />
      {/* /Sort By */}
      {/* Car Grid View */}
      <CarGridView
        plans={cars}
        brands={brands}
        categories={categories}
        priceRange={priceRange}
        selectedBrands={selectedBrands}
        selectedCategories={selectedCategories}
        selectedRatings={selectedRatings}
        selectedRecommendations={selectedRecommendations}
        onBrandChange={setSelectedBrands}
        onCategoryChange={setSelectedCategories}
        onPriceChange={setPriceRange}
        onRatingChange={setSelectedRatings}
        onRecommendationChange={setSelectedRecommendations}
        currentPage={currentPage}
        totalPages={totalPages}
        location={location}
        onPageChange={handlePageChange}        
      />
      {/* /Car Grid View */}
    </div>
  );
};

export default ListingGrid;
