import { BusinessInformation, RecordType, Review } from "../../../types/types";

export const reasons = [
    {
        id: 1,
        title: "Ahorra y Gana con Saldo Virtual",
        description:
            "Cada cuota que pagas te acerca más a tu auto soñado y acumula saldo virtual. Úsalo para reducir tus próximas cuotas o como adelanto. ¡Más ahorro, más beneficios!",
        icon: "assets/img/icons/bx-user-check.svg",
        styleClass: "choose-black",
    },
    {
        id: 2,
        title: "Multiplica tus Ganancias con Referidos",
        description:
            "Recomienda CuotaPlan a tus amigos y familiares y obtén comisiones en efectivo, puntos para sorteos, y descuentos exclusivos en tus cuotas. ¡Refiere, gana y ahorra más!",
        icon: "assets/img/icons/bx-user-check.svg",
        styleClass: "choose-secondary",
    },
    {
        id: 3,
        title: "Sorteos con Premios Millonarios",
        description:
            "Mantente al día con tus cuotas y participa automáticamente en sorteos con premios de hasta $1,500,000 o un auto 0km. ¡Tu ahorro te premia como nunca antes!",
        icon: "assets/img/icons/bx-user-check.svg",
        styleClass: "choose-primary",
    },
];

export const steps = [
    {
        id: 1,
        title: "Descubre el Plan que Mejor se Adapta a Vos",
        description:
            "Explora nuestra selección de planes de ahorro diseñados para ajustarse a tus necesidades. Filtra por marca, modelo y presupuesto para encontrar tu plan ideal. ¡Haz realidad tu sueño de un auto 0km con facilidad!",
        image: "assets/img/icons/services-icon-02.svg",
        borderClass: "border-primary",
        bgClass: "bg-primary",
    },
    {
        id: 2,
        title: "Regístrate en Solo Minutos",
        description:
            "Crea tu cuenta y comienza tu viaje hacia el auto de tus sueños. Con un proceso rápido, seguro y completamente en línea, unirte a CuotaPlan es más fácil que nunca.",
        image: "assets/img/icons/services-icon-01.svg",
        borderClass: "border-success",
        bgClass: "bg-success",
    },
    {
        id: 3,
        title: "Aprovecha los Beneficios Exclusivos",
        description:
            "Paga tus cuotas puntualmente y acumula saldo virtual desde la segunda cuota, que puedes usar para reducir futuras cuotas o adelantar pagos. Además, cada cuota pagada te otorga puntos para participar en sorteos con premios millonarios. Por cada amigo que refieras, obtendrás aún más puntos y beneficios exclusivos. ¡Con CuotaPlan, ahorrar nunca fue tan rentable!",
        image: "assets/img/icons/services-icon-03.svg",
        borderClass: "border-warning",
        bgClass: "bg-warning",
    },
];

export const stepsTransparency = [
    {
        id: 1,
        title: '¿De dónde provienen los fondos?',
        description:
            'CuotaPlan cobra una comisión por cada plan de ahorro vendido a suscriptores cumplidores. Desde esa comisión, se financian los saldos virtuales, los bonos por referidos y los sorteos.',
        image: 'assets/img/icons/transparency-icon-01.svg'
    },
    {
        id: 2,
        title: '¿Qué es un suscriptor cumplidor?',
        description:
            'Es aquel que paga al menos 4 cuotas consecutivas de su plan de ahorro. Solo los suscriptores cumplidores pueden acceder a sus saldos virtuales y participar plenamente en los beneficios.',
        image: 'assets/img/icons/transparency-icon-02.svg'
    },
    {
        id: 3,
        title: '¿Qué es un referido exitoso?',
        description:
            'Un referido que también ha pagado al menos 4 cuotas consecutivas. Los referidores solo obtienen el bono correspondiente si el referido cumple con estas condiciones.',
        image: 'assets/img/icons/transparency-icon-03.svg'
    },
    {
        id: 4,
        title: 'Plazos de pago de beneficios',
        description:
            'El saldo virtual se habilita 20 días después de cumplir las condiciones mínimas. Los bonos por referidos se otorgan 20 días después de que el referido cumpla con el mínimo de cuotas.',
        image: 'assets/img/icons/transparency-icon-04.svg'
    },
    {
        id: 5,
        title: 'Penalizaciones por incumplimiento',
        description:
            'Suscriptores y referidos que no cumplan con el mínimo establecido pueden perder puntos para sorteos y no tendrán acceso a beneficios monetarios.',
        image: 'assets/img/icons/transparency-icon-05.svg'
    },
    {
        id: 6,
        title: 'Garantía de transparencia',
        description:
            'Todos los movimientos de saldo virtual, bonos y puntos están registrados y respaldados, garantizando un sistema sostenible y justo.',
        image: 'assets/img/icons/transparency-icon-06.svg'
    },
];

export interface Benefit {
    id: number;
    title: {
        rendered: string;
    };
    content: {
        rendered: string;
    };
}

export const businessData: BusinessInformation = {
    id: 1,
    business_name: "Cuotaplan",
    cuit: "20-12345678-9",
    business_reason: "Plataforma líder en planes de ahorro",
    address: "Colon, 2358, Posadas, Misiones, Argentina",
    ceo_name: "Yassel Omar",
    ceo_lastname: "Izquierdo Souchay",
    phone: "+54-376-4xx-xxxx",
    email: "cuotaplan.com@gmail.com",
    geo_localidad_id: 1,
    opening_hours: {
        monday: "9am - 6pm",
        tuesday: "9am - 6pm",
        wednesday: "9am - 6pm",
        thursday: "9am - 6pm",
        friday: "9am - 6pm",
        saturday: "9am - 6pm",
    },
    description: "Cuotaplan ofrece los mejores planes de ahorro en Argentina.",
    facebook: "cuotaplan",
    twitter: "cuotaplan",
    instagram: "cuotaplan",
    linkedin: "cuotaplan",
    website: "https://www.cuotaplan.com",
    closing_days: "Sunday",
    additional_info: "Somos una empresa confiable y profesional.",
    created_at: "2023-01-01T00:00:00Z",
    updated_at: "2023-01-01T00:00:00Z",
    localidad: "Posadas",
    ourTeamData: [
        {
            id: 1,
            name: "María López",
            designation: "Gerente de Ventas",
            image: "team-member1.jpg",
            social_links: {
                facebook: "https://facebook.com/maria.lopez",
                twitter: "https://twitter.com/maria.lopez",
                linkedin: "https://linkedin.com/in/maria-lopez",
            },
        },
        {
            id: 2,
            name: "Carlos Martínez",
            designation: "Jefe de Operaciones",
            image: "team-member2.jpg",
            social_links: {
                facebook: "https://facebook.com/carlos.martinez",
                twitter: null,
                linkedin: "https://linkedin.com/in/carlos-martinez",
            },
        },
        {
            id: 3,
            name: "Lucía Fernández",
            designation: "Especialista en Marketing",
            image: "team-member3.jpg",
            social_links: {
                facebook: "https://facebook.com/lucia.fernandez",
                twitter: "https://twitter.com/lucia_fernandez",
                linkedin: null,
            },
        },
    ],
};

export const testimonialdata: Review[] = [
    {
        id: 1,
        author: "Juan Pérez",
        image: "https://example.com/images/juan.jpg",
        rating: 5,
        reviewText: "Excelente servicio, muy recomendado. La experiencia fue increíble.",
    },
    {
        id: 2,
        author: "Ana Gómez",
        image: "https://example.com/images/ana.jpg",
        rating: 4,
        reviewText: "Muy buen servicio, aunque hubo un pequeño retraso en el proceso.",
    },
    {
        id: 3,
        author: "Carlos Martínez",
        image: "https://example.com/images/carlos.jpg",
        rating: 5,
        reviewText: "El personal fue muy amable y profesional. Altamente recomendado.",
    },
    {
        id: 4,
        author: "Lucía Fernández",
        image: "https://example.com/images/lucia.jpg",
        rating: 3,
        reviewText: "La experiencia estuvo bien, pero podría mejorar en atención al cliente.",
    },
];


export const dataRecords: RecordType[] = [
    {
        id: '1',
        refid: '12345',
        transactionfor: 'Payment',
        date: '2023-10-01',
        total: '+$100',
        status: 'Completed',
        actions: null,
    },
    {
        id: '2',
        refid: '67890',
        transactionfor: 'Refund',
        date: '2023-10-02',
        total: '-$50',
        status: 'Pending',
        actions: null,
    },
];

export const faqsDetails = [
    {
        question: "¿Qué edad debo tener para unirme a un plan de ahorro?",
        answer:
            "Debes tener al menos 18 años para ser elegible. Si necesitas más información, consulta nuestras políticas de membresía.",
    },
    {
        question: "¿Qué documentos necesito para registrarme?",
        answer:
            "Necesitarás un documento de identidad válido, comprobante de ingresos y un formulario completo. Para más detalles, contáctanos.",
    },
    {
        question: "¿Qué tipos de vehículos están disponibles?",
        answer:
            "Tenemos una variedad de vehículos disponibles, incluyendo sedanes, SUVs y autos de lujo. Consulta nuestro catálogo para más información.",
    },
    {
        question: "¿Puedo realizar pagos con tarjeta de débito?",
        answer:
            "Sí, aceptamos pagos con tarjeta de débito, crédito y transferencias bancarias para mayor comodidad.",
    },
];


export const faqTransparencyData = [
    {
        question: "¿De dónde provienen los fondos de CuotaPlan?",
        answer: "Los fondos provienen de las comisiones cobradas a los concesionarios por cada plan de ahorro vendido a suscriptores cumplidores. Estas comisiones financian el saldo virtual, los bonos por referidos y los sorteos.",
    },
    {
        question: "¿Qué significa ser un suscriptor cumplidor?",
        answer: "Un suscriptor cumplidor es aquel que ha pagado al menos 4 cuotas consecutivas de su plan de ahorro. Solo estos suscriptores tienen acceso a los beneficios como saldo virtual y participación en sorteos.",
    },
    {
        question: "¿Qué es un referido exitoso?",
        answer: "Un referido exitoso es un suscriptor que, gracias a una recomendación, ha pagado al menos 4 cuotas consecutivas. Solo los referidores de estos usuarios obtienen los bonos correspondientes.",
    },
    {
        question: "¿Cuándo puedo usar mi saldo virtual?",
        answer: "El saldo virtual se habilita para los suscriptores cumplidores 20 días después de haber cumplido con el mínimo de pagos consecutivos requeridos.",
    },
    {
        question: "¿Cuándo se paga el bono por referidos?",
        answer: "El bono por referidos se otorga 20 días después de que el referido exitoso haya cumplido con el mínimo de pagos consecutivos establecidos.",
    },
    {
        question: "¿Qué sucede si no cumplo con el mínimo de pagos?",
        answer: "Los suscriptores o referidos que no cumplan con el mínimo de pagos pueden perder puntos para sorteos y acceso a beneficios monetarios.",
    },
    {
        question: "¿Cómo garantiza CuotaPlan la transparencia?",
        answer: "Cada saldo virtual, bono o punto tiene su registro detallado. Las órdenes de pago son generadas automáticamente, asegurando un sistema transparente y confiable.",
    },
];