import React from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { reasons } from "../data/us";

const WhyChooseUs = () => {
    

    return (
        <section className="section why-choose">
            <div className="choose-left">
                <ImageWithBasePath
                    src="assets/img/bg/choose-left.png"
                    className="img-fluid"
                    alt="Por qué elegir CuotaPlan"
                />
            </div>
            <div className="container">
                {/* Título de la sección */}
                <div className="section-heading" data-aos="fade-down">
                    <h2>¿Por Qué Elegir CuotaPlan?</h2>
                    <p>
                        Descubre cómo CuotaPlan transforma tu experiencia de ahorro automotriz con beneficios exclusivos diseñados para maximizar tu inversión.
                    </p>
                </div>
                {/* /Título de la sección */}
                <div className="why-choose-group">
                    <div className="row">
                        {reasons.map((reason) => (
                            <div
                                key={reason.id}
                                className="col-lg-4 col-md-6 col-12 d-flex"
                                data-aos="fade-down"
                            >
                                <div className="card flex-fill">
                                    <div className="card-body">
                                        <div className={`choose-img ${reason.styleClass}`}>
                                            <ImageWithBasePath src={reason.icon} alt={reason.title} />
                                        </div>
                                        <div className="choose-content">
                                            <h4>{reason.title}</h4>
                                            <p>{reason.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyChooseUs;