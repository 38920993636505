export const listingGridData = [
  {
    make: "Toyota",
    model: "Toyota Camry SE 350",
    image: "assets/img/cars/car-01.jpg",
    rating: 5.0,
    features: {
      type: "Auto",
      mileage: "10 KM",
      fuel: "Petrol",
      power: "Power",
      year: 2018,
      capacity: 5,
    },
    location: "Germany",
    price: "$400 per Day",
  },
  {
    make: "KIA",
    model: "Kia Soul 2016",
    image: "assets/img/cars/car-02.jpg",
    rating: 5.0,
    features: {
      type: "Auto",
      mileage: "22 KM",
      fuel: "Petrol",
      power: "Diesel",
      year: 2016,
      capacity: 5,
    },
    location: "Belgium",
    price: "$80 per Day",
  },
  {
    make: "Audi",
    model: "Audi A3 2019 new",
    image: "assets/img/cars/car-03.jpg",
    rating: 5.0,
    features: {
      type: "Manual",
      mileage: "10 KM",
      fuel: "Petrol",
      power: "Power",
      year: 2019,
      capacity: 4,
    },
    location: "New York, USA",
    price: "$45 per Day",
  },
  {
    make: "Ferrari",
    model: "Ferrari 458 MM Speciale",
    image: "assets/img/cars/car-04.jpg",
    rating: 5.0,
    features: {
      type: "Manual",
      mileage: "14 KM",
      fuel: "Diesel",
      power: "Basic",
      year: 2022,
      capacity: 5,
    },
    location: "New York, USA",
    price: "$160 per Day",
  },
  {
    make: "Chevrolet",
    model: "2018 Chevrolet Camaro",
    image: "assets/img/cars/car-05.jpg",
    rating: 5.0,
    features: {
      type: "Manual",
      mileage: "18 KM",
      fuel: "Diesel",
      power: "Power",
      year: 2018,
      capacity: 4,
    },
    location: "Germany",
    price: "$36 per Day",
  },
  {
    make: "Acura",
    model: "Acura Sport Version",
    image: "assets/img/cars/car-06.jpg",
    rating: 5.0,
    features: {
      type: "Auto",
      mileage: "12 KM",
      fuel: "Diesel",
      power: "Power",
      year: 2013,
      capacity: 5,
    },
    location: "New York, USA",
    price: "$30 per Day",
  },
  {
    make: "Chevrolet",
    model: "Chevrolet Pick Truck 3.5L",
    image: "assets/img/cars/car-07.jpg",
    rating: 5.0,
    features: {
      type: "Manual",
      mileage: "10 KM",
      fuel: "Petrol",
      power: "Power",
      year: 2012,
      capacity: 5,
    },
    location: "Spain",
    price: "$77 per Day",
  },
  {
    make: "Toyota",
    model: "Toyota Tacoma 4WD",
    image: "assets/img/cars/car-08.jpg",
    rating: 5.0,
    features: {
      type: "Auto",
      mileage: "22 miles",
      fuel: "Diesel",
      power: "Power",
      year: 2019,
      capacity: 5,
    },
    location: "Dallas, USA",
    price: "$30 per Day",
  },
  {
    make: "Accura",
    model: "Acura RDX FWD",
    image: "assets/img/cars/car-09.jpg",
    rating: 5.0,
    features: {
      type: "Auto",
      mileage: "42 miles",
      fuel: "Petrol",
      power: "Power",
      year: 2021,
      capacity: 5,
    },
    location: "Dallas, USA",
    price: "$80 per Day",
  },
  {
    make: "Kia",
    model: "Kia Soul 2016",
    image: "assets/img/cars/car-02.jpg",
    rating: 5.0,
    features: {
      type: "Auto",
      mileage: "42 miles",
      fuel: "Petrol",
      power: "Power",
      year: 2021,
      capacity: 5,
    },
    location: "Dallas, USA",
    price: "$77 per Day",
  },
];
