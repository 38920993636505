export const cars = [
    {
        id: 1,
        title: "Toyota Camry SE 350",
        brand: "Toyota",
        images: [
            "assets/img/cars/car-01.jpg",
            "assets/img/cars/car-01-slide1.jpg",
            "assets/img/cars/car-01-slide2.jpg",
            "assets/img/cars/car-01-slide3.jpg",
        ],
        reviews: 138,
        rating: 4,
        price: 160,
        virtualBalance: 8000, 
        referralBonus: 1600, 
        location: "Washington",
        features: [
            { icon: "assets/img/icons/lever.svg", label: "Automático" },
            { icon: "assets/img/icons/mileage.svg", label: "10 KM" },
            { icon: "assets/img/icons/fuel.svg", label: "Gasolina" },
            { icon: "assets/img/icons/steering-wheel.svg", label: "Potencia" },
            { icon: "assets/img/icons/motor.svg", label: "2018" },
            { icon: "assets/img/icons/passengers.svg", label: "5 Personas" },
        ],
        featureText: {
            featured: true,
            topRated: false,
        },
    },
    {
        id: 2,
        title: "Kia Soul 2016",
        brand: "KIA",
        images: [
            "assets/img/cars/car-02.jpg",
            "assets/img/cars/car-02-slide1.jpg",
            "assets/img/cars/car-02-slide2.jpg",
            "assets/img/cars/car-02-slide3.jpg",
        ],
        reviews: 170,
        rating: 4,
        price: 80,
        virtualBalance: 4000,
        referralBonus: 800,
        location: "Bélgica",
        features: [
            { icon: "assets/img/icons/lever.svg", label: "Automático" },
            { icon: "assets/img/icons/mileage.svg", label: "22 KM" },
            { icon: "assets/img/icons/fuel.svg", label: "Gasolina" },
            { icon: "assets/img/icons/steering-wheel.svg", label: "Diésel" },
            { icon: "assets/img/icons/motor.svg", label: "2016" },
            { icon: "assets/img/icons/passengers.svg", label: "5 Personas" },
        ],
        featureText: {
            featured: false,
            topRated: true,
        },
    },
    {
        id: 3,
        title: "Audi A3 2019 nuevo",
        brand: "Audi",
        images: ["assets/img/cars/car-03.jpg"],
        reviews: 150,
        rating: 4,
        price: 45,
        virtualBalance: 2250,
        referralBonus: 450,
        location: "Nueva York, EE.UU.",
        features: [
            { icon: "assets/img/icons/motor.svg", label: "Manual" },
            { icon: "assets/img/icons/mileage.svg", label: "10 KM" },
            { icon: "assets/img/icons/fuel.svg", label: "Gasolina" },
            { icon: "assets/img/icons/steering-wheel.svg", label: "Potencia" },
            { icon: "assets/img/icons/motor.svg", label: "2019" },
            { icon: "assets/img/icons/passengers.svg", label: "4 Personas" },
        ],
        featureText: {
            featured: false,
            topRated: true,
        },
    },
    {
        id: 4,
        title: "Ferrari 458 MM Speciale",
        brand: "Ferrari",
        images: ["assets/img/cars/car-04.jpg"],
        reviews: 160,
        rating: 4,
        price: 500,
        virtualBalance: 25000,
        referralBonus: 5000,
        location: "París, Francia",
        features: [
            { icon: "assets/img/icons/motor.svg", label: "Manual" },
            { icon: "assets/img/icons/mileage.svg", label: "15 KM" },
            { icon: "assets/img/icons/fuel.svg", label: "Gasolina" },
            { icon: "assets/img/icons/steering-wheel.svg", label: "Potencia" },
            { icon: "assets/img/icons/motor.svg", label: "2020" },
            { icon: "assets/img/icons/passengers.svg", label: "2 Personas" },
        ],
        featureText: {
            featured: true,
            topRated: false,
        },
    },
    {
        id: 5,
        title: "Mazda CX-5 2021",
        brand: "Mazda",
        images: [
            "assets/img/cars/car-05.jpg",
            "assets/img/cars/car-05-slide1.jpg",
        ],
        reviews: 95,
        rating: 5,
        price: 150,
        virtualBalance: 7500,
        referralBonus: 1500,
        location: "Tokio, Japón",
        features: [
            { icon: "assets/img/icons/lever.svg", label: "Automático" },
            { icon: "assets/img/icons/mileage.svg", label: "15 KM" },
            { icon: "assets/img/icons/fuel.svg", label: "Gasolina" },
            { icon: "assets/img/icons/motor.svg", label: "2021" },
            { icon: "assets/img/icons/passengers.svg", label: "5 Personas" },
        ],
        featureText: {
            featured: false,
            topRated: true,
        },
    },    
];

export const interstedCars = [
    {
        id: 1,
        image: "assets/img/cars/car-03.jpg",
        title: "Audi A3 2019 new",
        location: "Newyork, USA",
        price: 45,
        reviews: 150,
        rating: 4.0,
        features: [
            { icon: "assets/img/icons/lever.svg", label: "Manual" },
            { icon: "assets/img/icons/mileage.svg", label: "10 KM" },
            { icon: "assets/img/icons/fuel.svg", label: "Petrol" },
            { icon: "assets/img/icons/steering-wheel.svg", label: "Power" },
            { icon: "assets/img/icons/motor.svg", label: "2019" },
            { icon: "assets/img/icons/passengers.svg", label: "4 Persons" },
        ],
        authorImage: "assets/img/profiles/avatar-03.jpg",
    },
    {
        id: 2,
        image: "assets/img/cars/car-01.jpg",
        title: "Toyota Camry SE 350",
        location: "Washington",
        price: 160,
        reviews: 138,
        rating: 4.0,
        features: [
            { icon: "assets/img/icons/lever.svg", label: "Auto" },
            { icon: "assets/img/icons/mileage.svg", label: "10 KM" },
            { icon: "assets/img/icons/fuel.svg", label: "Petrol" },
            { icon: "assets/img/icons/steering-wheel.svg", label: "Power" },
            { icon: "assets/img/icons/motor.svg", label: "2018" },
            { icon: "assets/img/icons/passengers.svg", label: "5 Persons" },
        ],
        authorImage: "assets/img/profiles/avatar-04.jpg",
    },
    {
        id: 3,
        image: "assets/img/cars/car-04.jpg",
        title: "Ferrari 458 MM Speciale",
        location: "Newyork, USA",
        price: 160,
        reviews: 160,
        rating: 4.0,
        features: [
            { icon: "assets/img/icons/lever.svg", label: "Manual" },
            { icon: "assets/img/icons/mileage.svg", label: "14 KM" },
            { icon: "assets/img/icons/fuel.svg", label: "Diesel" },
            { icon: "assets/img/icons/steering-wheel.svg", label: "Basic" },
            { icon: "assets/img/icons/motor.svg", label: "2022" },
            { icon: "assets/img/icons/passengers.svg", label: "5 Persons" },
        ],
        authorImage: "assets/img/profiles/avatar-04.jpg",
    },
    {
        id: 4,
        image: "assets/img/cars/car-05.jpg",
        title: "2018 Chevrolet Camaro",
        location: "Germany",
        price: 36,
        reviews: 200,
        rating: 5.0,
        features: [
            { icon: "assets/img/icons/lever.svg", label: "Manual" },
            { icon: "assets/img/icons/mileage.svg", label: "18 KM" },
            { icon: "assets/img/icons/fuel.svg", label: "Diesel" },
            { icon: "assets/img/icons/steering-wheel.svg", label: "Power" },
            { icon: "assets/img/icons/motor.svg", label: "2018" },
            { icon: "assets/img/icons/passengers.svg", label: "4 Persons" },
        ],
        authorImage: "assets/img/profiles/avatar-05.jpg",
    },
    {
        id: 5,
        image: "assets/img/cars/car-06.jpg",
        title: "Acura Sport Version",
        location: "Newyork, USA",
        price: 30,
        reviews: 125,
        rating: 4.0,
        features: [
            { icon: "assets/img/icons/lever.svg", label: "Auto" },
            { icon: "assets/img/icons/mileage.svg", label: "12 KM" },
            { icon: "assets/img/icons/fuel.svg", label: "Diesel" },
            { icon: "assets/img/icons/steering-wheel.svg", label: "Power" },
            { icon: "assets/img/icons/motor.svg", label: "2013" },
            { icon: "assets/img/icons/passengers.svg", label: "5 Persons" },
        ],
        authorImage: "assets/img/profiles/avatar-06.jpg",
    },
];
export const brands = [
    { label: "Tesla", value: "tesla" },
    { label: "Ford", value: "ford" },
    { label: "Mercedes-Benz", value: "mercedes-benz" },
    { label: "Audi", value: "audi" },
];

export const categories = [
    { label: "Convertible", value: "convertible" },
    { label: "Coupé", value: "coupe" },
    { label: "Sedán", value: "sedan" },
    { label: "SUV", value: "suv" },
];

// Datos de los tipos de autos
export const carTypes = [
    {
      id: 1,
      name: "Crossover",
      count: 35,
      image: "assets/img/cars/mp-vehicle-01.png",
    },
    {
      id: 2,
      name: "Cupé Deportivo",
      count: 45,
      image: "assets/img/cars/mp-vehicle-02.png",
    },
    {
      id: 3,
      name: "Sedán",
      count: 15,
      image: "assets/img/cars/mp-vehicle-03.png",
    },
    {
      id: 4,
      name: "Pickup",
      count: 17,
      image: "assets/img/cars/mp-vehicle-04.png",
    },
    {
      id: 5,
      name: "Monovolumen Familiar",
      count: 24,
      image: "assets/img/cars/mp-vehicle-05.png",
    },
  ];


  // Lista de autos populares
  export const mostPopularCars = [
    {
        id: 1,
        brand: "Mazda",
        name: "Mazda 6 Touring",
        location: "Washington",
        price: 160,
        year: 2018,
        fuel: "Nafta",
        transmission: "Automático",
        passengers: 5,
        mileage: "10 KM",
        rating: 4.0,
        reviews: 138,
        image: "assets/img/cars/car-01.jpg",
    },
    {
        id: 2,
        brand: "Audi",
        name: "Audi A3 2019 Nuevo",
        location: "Nueva York, EE.UU.",
        price: 45,
        year: 2019,
        fuel: "Nafta",
        transmission: "Manual",
        passengers: 4,
        mileage: "10 KM",
        rating: 4.5,
        reviews: 150,
        image: "assets/img/cars/car-03.jpg",
    },
    {
        id: 3,
        brand: "Toyota",
        name: "Toyota Camry SE 350",
        location: "California",
        price: 75,
        year: 2020,
        fuel: "Diesel",
        transmission: "Automático",
        passengers: 5,
        mileage: "15 KM",
        rating: 4.2,
        reviews: 120,
        image: "assets/img/cars/car-02.jpg",
    },
];
// Lista de marcas con logotipos
export const brandsWithLogos = [
    { id: 1, name: "Mazda", icon: "assets/img/icons/car-icon-01.svg" },
    { id: 2, name: "Audi", icon: "assets/img/icons/car-icon-02.svg" },
    { id: 3, name: "Honda", icon: "assets/img/icons/car-icon-03.svg" },
    { id: 4, name: "Toyota", icon: "assets/img/icons/car-icon-04.svg" },
    { id: 5, name: "Acura", icon: "assets/img/icons/car-icon-05.svg" },
    { id: 6, name: "Tesla", icon: "assets/img/icons/car-icon-06.svg" },
];

export const facts = [
    {
        id: 1,
        icon: "assets/img/icons/bx-heart.svg",
        value: 16,
        suffix: "K+",
        title: "Suscriptores Activos",
        description: "Miles de personas ya confían en CuotaPlan para alcanzar el auto de sus sueños.",
        duration: 3,
    },
    {
        id: 2,
        icon: "assets/img/icons/bx-car.svg",
        value: 25,
        suffix: "+",
        title: "Concesionarios Asociados",
        description: "Trabajamos con los concesionarios más importantes para ofrecerte la mejor experiencia.",
        duration: 3,
    },
    {
        id: 3,
        icon: "assets/img/icons/bx-headphone.svg",
        value: 50,
        suffix: "K+",
        title: "Puntos Otorgados",
        description: "Miles de puntos entregados para participar en nuestros sorteos millonarios.",
        duration: 3,
    },
    {
        id: 4,
        icon: "assets/img/icons/bx-history.svg",
        value: 10,
        suffix: "M+",
        title: "Premios Entregados",
        description: "Más de $10,000,000 en premios otorgados a nuestros suscriptores. ¡Tú podrías ser el próximo ganador!",
        duration: 3,
    },
];
  export const recommendedCars = [
    {
        id: 55,
        name: "BMW 640 XI Gran Turismo",
        price: 400,
        rating: 5.0,
        features: ["Automático", "10 KM", "Diesel", "Potencia", "AC", "5 Personas"],
        image: "assets/img/cars/rental-car-01.jpg",
    },
    {
        id: 56,
        name: "Camz Ferrari Portofino M",
        price: 210,
        rating: 5.0,
        features: ["Automático", "30 KM", "Diesel", "Potencia", "AC", "5 Personas"],
        image: "assets/img/cars/rental-car-02.jpg",
    },
    {
        id: 57,
        name: "Mercedes-Benz",
        price: 380,
        rating: 5.0,
        features: ["Automático", "30 KM", "Diesel", "Potencia", "AC", "5 Personas"],
        image: "assets/img/cars/rental-car-03.jpg",
    },
    {
        id: 58,
        name: "Range Rover",
        price: 250,
        rating: 4.5,
        features: ["Automático", "28 KM", "Gasolina", "Potencia", "AC", "5 Personas"],
        image: "assets/img/cars/rental-car-04.jpg",
    },
];

// Arreglo de nombres de imágenes
export const homeImages = [    
    'yaris-negro',
    'corolla-cross-gris',
    'yaris-rojo',    
    'corolla-cross-azul',
    'crown-negro',
    'yaris-gris-oscuro',
    'sw4-gris',    
    'grm-86rt',
];


const images = [
    {
        thumb: "assets/img/gallery/gallery-thumb-01.jpg",
        big: "/react/template/assets/img/gallery/gallery-big-01.jpg",
},
    {
        thumb: "assets/img/gallery/gallery-thumb-02.jpg",
        big: "/react/template/assets/img/gallery/gallery-big-02.jpg",
    },
    {
        thumb: "assets/img/gallery/gallery-thumb-03.jpg",
        big: "/react/template/assets/img/gallery/gallery-big-03.jpg",
    },
    {
        thumb: "assets/img/gallery/gallery-thumb-04.jpg",
        big: "/react/template/assets/img/gallery/gallery-big-04.jpg",
    },
];

export const settings = {
    dots: false,
    autoplay: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 776,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 567,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};
export const types = [
    { name: " Newyork Office - 78, 10th street Laplace USA" },
    { name: "Newyork Office - 12, 5th street USA" },
];
export const Location = [
    { name: " Newyork Office - 78, 10th street Laplace USA" },
    { name: "Newyork Office - 12, 5th street USA" },
];

export const specifications = [
    { icon: "assets/img/specification/specification-icon-1.svg", label: "Carrocería", value: "Sedán" },
    { icon: "assets/img/specification/specification-icon-2.svg", label: "Marca", value: "Nissan" },
    { icon: "assets/img/specification/specification-icon-3.svg", label: "Transmisión", value: "Automática" },
    { icon: "assets/img/specification/specification-icon-4.svg", label: "Tipo de Combustible", value: "Diésel" },
    { icon: "assets/img/specification/specification-icon-5.svg", label: "Kilometraje", value: "16 Km" },
    { icon: "assets/img/specification/specification-icon-6.svg", label: "Tracción", value: "Delantera" },
    { icon: "assets/img/specification/specification-icon-7.svg", label: "Año", value: "2018" },
    { icon: "assets/img/specification/specification-icon-8.svg", label: "AC", value: "Aire Acondicionado" },
    { icon: "assets/img/specification/specification-icon-9.svg", label: "VIN", value: "45456444" },
    { icon: "assets/img/specification/specification-icon-10.svg", label: "Puertas", value: "4 Puertas" },
    { icon: "assets/img/specification/specification-icon-11.svg", label: "Frenos", value: "ABS" },
    { icon: "assets/img/specification/specification-icon-12.svg", label: "Motor (Hp)", value: "3,000" },
];