import axios from 'axios';

// Configuración dinámica utilizando variables de entorno
const Config = {
    apiUrl: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000', // URL de la API
    defaultToken: process.env.REACT_APP_API_TOKEN || '', // Token predeterminado
    region: process.env.REACT_APP_DEFAULT_REGION || 'default-region', // Región predeterminada
    country: process.env.REACT_APP_DEFAULT_COUNTRY || 'default-country', // País predeterminado
    showMultipleConcesionarios: process.env.REACT_APP_SHOW_MULTIPLE_CONCESIONARIO === 'true', // Convertir a booleano
    tokenKey: process.env.REACT_APP_TOKEN_KEY || 'authToken', // Clave del token desde la configuración
    displayMode: process.env.REACT_APP_DISPLAY_MODE || 'single', // Modo de visualización
};

// Configuración del token de autenticación
export const setAuthToken = (token: string) => {
    if (token) {
        localStorage.setItem(Config.tokenKey, token);
    } else {
        localStorage.removeItem(Config.tokenKey);
    }
};

export const getAuthToken = () => {
    let token = localStorage.getItem(Config.tokenKey);
    if (!token) {
        // Si no existe el token, establecer el token por defecto
        setAuthToken(Config.defaultToken);
        token = Config.defaultToken || null;
    }
    return token;
};

// Crear instancia de Axios con la base URL de la API
const api = axios.create({
    baseURL: Config.apiUrl,
});

// Interceptor para añadir el token en cada solicitud
api.interceptors.request.use(
    config => {
        const token = getAuthToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Función para obtener toda la información del negocio
export const getBusinessInformation = async () => {
    try {
        const response = await api.get('/business-informations');
        return response.data;
    } catch (error) {
        console.error('Error fetching business information:', error);
        throw error;
    }
};

// Función para obtener información de negocio por ID
export const getBusinessInformationById = async (id: number) => {
    try {
        const response = await api.get(`/business-informations/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching business information with id ${id}:`, error);
        throw error;
    }
};
// Función para enviar mensaje de contacto
export const sendContactMessage = async (contactData: {
    nombre: string; // Nombre de la persona
    email: string;  // Correo electrónico
    tipoContacto: string; // Tipo de contacto
    asunto: string; // Asunto del mensaje
    mensaje: string; // Mensaje del contacto
}) => {
    try {
        const response = await api.post('/misc/contact', {
            nombre: contactData.nombre,
            email: contactData.email,
            tipoContacto: contactData.tipoContacto,
            asunto: contactData.asunto,
            mensaje: contactData.mensaje,
        });
        return response.data;
    } catch (error) {
        console.error('Error sending contact message:', error);
        throw error;
    }
};
export default api;