import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { DetailProductProps } from "../../../types/cars-types";

const DetailHeader: React.FC<DetailProductProps> = ({ plan }) => {
    if (!plan || !plan.vehicle) {
        return null; 
    }

    const renderStars = (rating: number) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <i
                    key={i}
                    className={`fas fa-star ${i < rating ? 'filled' : ''}`}
                />
            );
        }
        return stars;
    };

    return (
        <section className="product-detail-head">
            <div className="container">
                <div className="detail-page-head">
                    <div className="detail-headings">
                        <div className="star-rated">
                            <ul className="list-rating">
                                <li>
                                    <div className="car-brand">
                                        <span>
                                            <ImageWithBasePath
                                                src="assets/img/icons/car-icon.svg"
                                                alt={plan.vehicle.type}
                                            />
                                        </span>
                                        {plan.vehicle.type}
                                    </div>
                                </li>
                                <li>
                                    <span className="year">{plan.vehicle.year}</span>
                                </li>
                                <li className="ratings">
                                    {renderStars(plan.rating)}
                                    <span className="d-inline-block average-list-rating">
                                        ({plan.rating})
                                    </span>
                                </li>
                            </ul>
                            <div className="camaro-info">
                                <h3>{plan.name}</h3>
                                <div className="camaro-location">
                                    <div className="camaro-location-inner">
                                        <i className="bx bx-map" />
                                        <span>{plan.dealer.fullAddress}.</span>
                                    </div>
                                    <div className="camaro-location-inner">
                                        <i className="bx bx-show" />
                                        <span>Vistas: {plan.visits}</span>
                                    </div>
                                    <div className="camaro-location-inner">
                                        <i className="bx bx-car" />
                                        <span>Publicado: {new Date(plan.published).toLocaleDateString()}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details-btn">
                        <span className="total-badge">
                            <i className="bx bx-calendar-edit" />
                            Suscriptores Totales: {plan.subscribersCount}
                        </span>
                        <Link to="#">
                            <i className="bx bx-dollar-circle" />
                            Suscribir
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DetailHeader;