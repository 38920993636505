import React from "react";
import { CornerDownLeft } from "react-feather";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { all_routes } from "../router/all_routes";

const routes = all_routes;
const ForgotPassword = () => {
  return (
    <>
      <div className="main-wrapper login-body">
        {/* Encabezado */}
        <header className="log-header">
          <Link to={routes.homeOne}>
            <ImageWithBasePath
              className="img-fluid logo-dark"
              src="assets/img/logo.svg"
              alt="Logo"
            />
          </Link>
        </header>
        {/* /Encabezado */}
        <div className="login-wrapper">
          <div className="loginbox">
            <div className="login-auth">
              <div className="login-auth-wrap">
                <div className="sign-group">
                  <Link to={routes.homeOne} className="btn sign-up">
                    <span>
                      <CornerDownLeft />
                    </span>{" "}
                    Volver al Inicio
                  </Link>
                </div>
                <h1>¿Olvidaste tu Contraseña?</h1>
                <p className="account-subtitle">
                  Ingresa tu correo electrónico y te enviaremos un enlace para
                  restablecer tu contraseña.
                </p>
                <form action="index#">
                  <div className="input-block">
                    <label className="form-label">
                      Dirección de Correo Electrónico{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <Link
                    to={routes.resetPassword}
                    className="btn btn-outline-light w-100 btn-size"
                  >
                    Guardar Cambios
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Pie de Página */}
        <footer className="log-footer">
          <div className="container-fluid">
            {/* Derechos de Autor */}
            <div className="copyright">
              <div className="copyright-text">
                <p>© 2023 CuotaPlan. Todos los derechos reservados.</p>
              </div>
            </div>
            {/* /Derechos de Autor */}
          </div>
        </footer>
        {/* /Pie de Página */}
      </div>
    </>
  );
};

export default ForgotPassword;