import React from "react";

interface DescriptionSectionProps {

    description: string;

}



const DescriptionSection: React.FC<DescriptionSectionProps> = ({ description }) => {
    if (!description || description.trim() === '') {
        return null; 
    }

    return (
        <div className="review-sec mb-0">
            <div className="review-header">
                <h4>Descripción del Plan</h4>
            </div>
            <div className="description-list">
                <p>
                   {description}
                </p>
                {/* <p>
                    Se popularizó en la década de 1960 con el lanzamiento de hojas de
                    Letraset que contenían pasajes de Lorem Ipsum, y más recientemente con
                    software de autoedición como Aldus PageMaker que incluye versiones de
                    Lorem Ipsum.
                </p>
                <p>
                    Se popularizó en la década de 1960 con el lanzamiento de hojas de
                    Letraset que contenían pasajes de Lorem Ipsum, y más recientemente con
                    software de autoedición como Aldus PageMaker que incluye versiones de
                    Lorem Ipsum.
                </p> */}
                {/* <div className="read-more">
                    <div className="more-text">
                        <p>
                            Se popularizó en la década de 1960 con el lanzamiento de hojas de
                            Letraset que contenían pasajes de Lorem Ipsum, y más recientemente
                            con software de autoedición como Aldus PageMaker que incluye
                            versiones de Lorem Ipsum.
                        </p>
                    </div>
                    <Link to="#" className="more-link">
                        Mostrar Más
                    </Link>
                </div> */}
            </div>
        </div>
    );
};

export default DescriptionSection;
