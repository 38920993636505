import React, { useState } from "react";
import { CornerDownLeft } from 'react-feather';
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { all_routes } from "../router/all_routes";

const routes = all_routes;
const Login = () => {

  const [isToggle, setIsToggle] = useState(false);

  return (
    <div className="main-wrapper login-body">
      {/* Encabezado */}
      <header className="log-header">
        <Link to={routes.homeOne}>
          <ImageWithBasePath
            className="img-fluid logo-dark"
            src="assets/img/logo.svg"
            alt="Logo"
          />
        </Link>
      </header>
      {/* /Encabezado */}
      <div className="login-wrapper">
        <div className="loginbox">
          <div className="login-auth">
            <div className="login-auth-wrap">
              <div className="sign-group">
                <Link to={routes.homeOne} className="btn sign-up">
                  <span>
                    <CornerDownLeft />
                  </span>{" "}
                  Volver al Inicio
                </Link>
              </div>
              <h1>Iniciar Sesión</h1>
              <p className="account-subtitle">
                Te enviaremos un código de confirmación a tu correo electrónico.
              </p>
              <form>
                <div className="input-block">
                  <label className="form-label">
                    Correo Electrónico <span className="text-danger">*</span>
                  </label>
                  <input type="email" className="form-control" placeholder="" />
                </div>
                <div className="input-block">
                  <label className="form-label">
                    Contraseña <span className="text-danger">*</span>
                  </label>
                  <div
                    className="pass-group"
                    onClick={() => setIsToggle(!isToggle)}
                  >
                    <input
                      type={isToggle ? "text" : "password"}
                      className="form-control pass-input"
                      placeholder=""
                    />
                    <span
                      className={`fas toggle-password ${
                        isToggle ? "fa-eye" : "fa-eye-slash"
                      }`}
                    />
                  </div>
                </div>
                <div className="input-block">
                  <Link className="forgot-link" to={routes.forgotPassword}>
                    ¿Olvidaste tu contraseña?
                  </Link>
                </div>
                <div className="input-block m-0">
                  <label className="custom_check d-inline-flex">
                    <span>Recordarme</span>
                    <input type="checkbox" name="remeber" />
                    <span className="checkmark" />
                  </label>
                </div>
                <Link
                  to={routes.homeOne}
                  className="btn btn-outline-light w-100 btn-size mt-1"
                >
                  Iniciar Sesión
                </Link>
                <div className="login-or">
                  <span className="or-line" />
                  <span className="span-or-log">
                    O, inicia sesión con tu correo
                  </span>
                </div>
                {/* Inicio de Sesión Social */}
                <div className="social-login">
                  <Link
                    to="#"
                    className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                  >
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/google.svg"
                        className="img-fluid"
                        alt="Google"
                      />
                    </span>
                    Iniciar sesión con Google
                  </Link>
                </div>
                <div className="social-login">
                  <Link
                    to="#"
                    className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                  >
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/facebook.svg"
                        className="img-fluid"
                        alt="Facebook"
                      />
                    </span>
                    Iniciar sesión con Facebook
                  </Link>
                </div>
                {/* /Inicio de Sesión Social */}
                <div className="text-center dont-have">
                  <Link to={routes.register}>Registrarse</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Pie de Página */}
      <footer className="log-footer">
        <div className="container-fluid">
          {/* Derechos de Autor */}
          <div className="copyright">
            <div className="copyright-text">
              <p>© 2023 CuotaPlan. Todos los derechos reservados.</p>
            </div>
          </div>
          {/* /Derechos de Autor */}
        </div>
      </footer>
      {/* /Pie de Página */}
    </div>
  );
};

export default Login;