import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { getMediaByName, getPostById, getPostBySlug } from '../../../api/wordpress/posts';
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';
import { all_routes } from '../../../feature-module/router/all_routes';
import { stripTags } from '../../../utils';
import { homeImages } from '../data/cars';

const HomeBanner: React.FC = () => {
    const routes = all_routes;

    const [bannerText, setBannerText] = useState<string>('');
    const [initialMessage, setInitialMessage] = useState<string>('');
    const [planDeAhorroText, setPlanDeAhorroText] = useState<string>('');
    const [descubreElMejorText, setDescubreElMejorText] = useState<string>('');
    const [bannerUrl, setBannerUrl] = useState<string>('');
    

    useEffect(() => {
        const fetchBannerData = async () => {
            try {
                const [bannerPost, initialMessagePost, planDeAhorroPost, descubreElMejorPost] = await Promise.all([
                    //plataforma-confiable-para-planes-de-ahorro-en-argentina
                    getPostById(158),
                    getPostBySlug('mensaje-inicial'),
                    getPostBySlug('plan-de-ahorro-para-tu-vehiculo'),
                    getPostBySlug('descubre-el-mejor'),
                ]);

                if (bannerPost) setBannerText(stripTags(bannerPost.content.rendered || ''));
                if (initialMessagePost) setInitialMessage(stripTags(initialMessagePost.content.rendered || ''));
                if (planDeAhorroPost) setPlanDeAhorroText(stripTags(planDeAhorroPost.content.rendered || ''));
                if (descubreElMejorPost) setDescubreElMejorText(stripTags(descubreElMejorPost.content.rendered || ''));

                // Selección aleatoria de imagen
                const randomImageName = homeImages[Math.floor(Math.random() * homeImages.length)];
                const banner = await getMediaByName(randomImageName);

                if (banner && banner[0]?.media_details?.sizes?.['full']) {
                    setBannerUrl(banner[0].media_details.sizes['full'].source_url);
                } else {
                    console.error('Error: No suitable banner image found.');
                }
            } catch (error) {
                console.error('Error fetching banner data:', error);
            }
        };

        fetchBannerData();
    }, []);

    return (
        <section className="banner-section banner-slider">
            <div className="container">
                <div className="home-banner">
                    <div className="row align-items-center">
                        {/* Contenido Izquierdo */}
                        <div className="col-lg-6" data-aos="fade-down">
                            <p className="explore-text">
                                <span>
                                    <i className="fa-solid fa-thumbs-up me-2"></i>
                                </span>
                                {bannerText}
                            </p>
                            <h1>
                                <span>{descubreElMejorText}</span>
                                <br />
                                {planDeAhorroText}
                            </h1>
                            <p dangerouslySetInnerHTML={{ __html: initialMessage }} />
                            <div className="view-all">
                                <Link
                                    to={routes.listingGrid}
                                    className="btn btn-view d-inline-flex align-items-center"
                                >
                                    Explora Tu Plan Ideal Ahora
                                    <span>
                                        <i className="feather icon-arrow-right ms-2" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                        {/* Imagen Derecha */}
                        <div className="col-lg-6" data-aos="fade-down">
                            <div className="banner-imgs">
                                {bannerUrl && (
                                    <ImageWithBasePath
                                        src={bannerUrl}
                                        className="img-fluid aos"
                                        alt="Banner"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeBanner;