// src/api/wordpress/index.ts
import axios from 'axios';

console.log('WordPress Site URL:', process.env.REACT_APP_WORDPRESS_INSTANCE);

const wordpressAPI = axios.create({
  baseURL: process.env.REACT_APP_WORDPRESS_INSTANCE,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default wordpressAPI;