import { brands, categories } from "../data/cars";
import FooterContact from "./FooterContact";
import FooterMenu from "./FooterMenu";

const FooterTop = () => {
  // Obtener marcas únicas (planes)
  const plans = brands.map((brand) => ({
    text: `Planes ${brand.label}`,
    to: `#`,
  }));

  // Mapear categorías para los enlaces
  const vehicleCategories = categories.map((category) => ({
    text: category.label,
    to: `#`,
  }));

  return (
    <div className="footer-top aos" data-aos="fade-down">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="row">
              <FooterMenu title="Sobre CuotaPlan" links={plans} />
              <FooterMenu
                title="Categorías de Vehículos"
                links={[
                  { text: "Todos los Vehículos", to: "#" },
                  ...vehicleCategories,
                ]}
              />
              <FooterMenu
                title="Enlaces Rápidos"
                links={[
                  { text: "Contacto", to: "/contact-us" }, 
                  { text: "Mi Cuenta", to: "#" },
                  { text: "Promociones", to: "#" },
                  { text: "Concesionarios Asociados", to: "#" },
                  { text: "Ofertas y Beneficios", to: "#" },
                  { text: "Servicios Financieros", to: "#" },                  
                ]}
              />
            </div>
          </div>
          <div className="col-lg-5">
            <FooterContact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterTop;