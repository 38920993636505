
import { Plan } from '../../types/cars-types';
import api from '../cuotaplan-api';

// Función para agregar un retraso
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Obtener lista de modelos
export const getModels = async (type: string): Promise<{ id: number; modelo: string }[]> => {
  try {
    const response = await api.get(`/filters/models?type=${type}`);
    return response.data; // Suponiendo que la API devuelve un array de modelos
  } catch (error) {
    console.error('Error fetching models:', error);
    throw error;
  }
};

// Obtener lista de marcas
export const getBrands = async (type: string): Promise<{ id: number; nombre: string; logotipo: string }[]> => {
  try {
    const response = await api.get(`/filters/brands?type=${type}`);
    return response.data; // Suponiendo que la API devuelve un array de marcas
  } catch (error) {
    console.error('Error fetching brands:', error);
    throw error;
  }
};

// Obtener lista de condiciones (Nuevo/Usado)
export const getConditions = async (type: string): Promise<{ id: number; name: string }[]> => {
  try {
    const response = await api.get(`/filters/conditions?type=${type}`);
    return response.data; // Suponiendo que la API devuelve un array de condiciones
  } catch (error) {
    console.error('Error fetching conditions:', error);
    throw error;
  }
};

// Obtener rango de precios
export const getPriceRange = async (type: string): Promise<{ min: number; max: number }> => {
  try {
    const response = await api.get(`/filters/price-range?type=${type}`);
    return response.data; // Suponiendo que la API devuelve un objeto { min, max }
  } catch (error) {
    console.error('Error fetching price range:', error);
    throw error;
  }
};

// Obtener lista de coches
export const getPlans = async (params: any, retries = 3): Promise<Plan[]> => {
  try {
    const response = await api.post('/plans/grid-view', { params });
    return response.data.data.data; // Devuelve un array de coches
  } catch (error) {
    if ((error as any).response && (error as any).response.status === 429 && retries > 0) {
      console.warn('Too many requests, retrying...');
      await delay(1000); // Espera 1 segundo antes de reintentar
      return getPlans(params, retries - 1);
    } else {
      console.error('Error fetching cars:', error);
      throw error;
    }
  }
};

// Obtener información del plan por modelo
export const getPlanByModel = async (model: string): Promise<any> => {
  try {
    const response = await api.get(`/plans/model/${model}`);
    return response.data; // Devuelve la información del plan
  } catch (error) {
    console.error('Error fetching plan by model:', error);
    throw error;
  }
};