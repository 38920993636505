import { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ImageWithBasePath from "../../../../core/data/img/ImageWithBasePath";
import { all_routes } from "../../../../feature-module/router/all_routes";
import { interstedCars } from "../../data/cars";

const InterestedCars = () => {
    const routes = all_routes;
    const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));

    const handleItemClick = (index: number) => {
        setSelectedItems((prevSelectedItems) => {
            const updatedSelectedItems = [...prevSelectedItems];
            updatedSelectedItems[index] = !updatedSelectedItems[index];
            return updatedSelectedItems;
        });
    };

    const settings = {
        dots: false,
        autoplay: false,
        slidesToShow: 3,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 776,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 567,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="row">
            <div className="col-md-12">                
                <div className="details-car-grid">
                    <div className="details-slider-heading">
                        <h3>Podrías Estar Interesado</h3>
                    </div>
                    <div className="rental-deal-slider details-car owl-carousel">
                        <Slider {...settings}>
                            {interstedCars.map((car, index) => (
                                <div className="rental-car-item" key={car.id}>
                                    <div className="listing-item pb-0">
                                        <div className="listing-img">
                                            <Link to={routes.listingDetails}>
                                                <ImageWithBasePath
                                                    src={car.image}
                                                    className="img-fluid"
                                                    alt={car.title}
                                                />
                                            </Link>
                                            <div
                                                className="fav-item justify-content-end"
                                                onClick={() => handleItemClick(index)}
                                            >
                                                <Link
                                                    to="#"
                                                    className={`fav-icon ${selectedItems[index] ? "selected" : ""
                                                        }`}
                                                >
                                                    <i className="feather icon-heart" />
                                                </Link>
                                            </div>
                                            <span className="featured-text">{car.title.split(" ")[0]}</span>
                                        </div>
                                        <div className="listing-content">
                                            <div className="listing-features d-flex align-items-end justify-content-between">
                                                <div className="list-rating">
                                                    <Link to="#" className="author-img">
                                                        <ImageWithBasePath src={car.authorImage} alt="author" />
                                                    </Link>
                                                    <h3 className="listing-title">
                                                        <Link to={routes.listingDetails}>{car.title}</Link>
                                                    </h3>
                                                    <div className="list-rating">
                                                        {[...Array(Math.floor(car.rating))].map((_, starIdx) => (
                                                            <i className="fas fa-star filled" key={starIdx} />
                                                        ))}
                                                        <span>({car.rating}) {car.reviews} Reviews</span>
                                                    </div>
                                                </div>
                                                <div className="list-km">
                                                    <span className="km-count">
                                                        <ImageWithBasePath
                                                            src="assets/img/icons/map-pin.svg"
                                                            alt="Location"
                                                        />
                                                        3.5m
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="listing-details-group">
                                                <ul>
                                                    {car.features.slice(3).map((feature, index) => (
                                                        <li key={index}>
                                                            <span>
                                                                <ImageWithBasePath
                                                                    src={feature.icon}
                                                                    alt={feature.label}
                                                                />
                                                            </span>
                                                            <p>{feature.label}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="listing-location-details">
                                                <div className="listing-price">
                                                    <span>
                                                        <i className="feather-map-pin" />
                                                    </span>
                                                    {car.location}
                                                </div>
                                                <div className="listing-price">
                                                    <h6>
                                                        ${car.price} <span>/ Día</span>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="listing-button">
                                                <Link to={routes.listingDetails} className="btn btn-order">
                                                    <span>
                                                        <i className="feather-calendar me-2" />
                                                    </span>
                                                    Reservar Ahora
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InterestedCars;
