import { Dropdown } from "primereact/dropdown";
import React from "react";
import { numberOptions, sortOptions } from "../data/numbers";

interface SortByProps {
  selectedNumber: any;
  setSelectedNumber: (value: any) => void;
  selectedSort: any;
  setSelectedSort: (value: any) => void;
}

const SortByPlans: React.FC<SortByProps> = ({
  selectedNumber,
  setSelectedNumber,
  selectedSort,
  setSelectedSort,
}) => {
  

  return (
    <div className="sort-section">
      <div className="container">
        <div className="sortby-sec">
          <div className="sorting-div">
            <div className="row d-flex align-items-center">
              <div className="col-xl-4 col-lg-3 col-sm-12 col-12">
                <div className="count-search">
                  <p>Mostrando 1-9 de 154 resultados</p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 col-sm-12 col-12">
                <div className="product-filter-group">
                  <div className="sortbyset">
                    <ul className="d-flex">
                      <li>
                        <span className="sortbytitle">Mostrar:</span>
                        <div className="sorting-select select-one">
                          <Dropdown
                            value={selectedNumber}
                            onChange={(e) => setSelectedNumber(e.value)}
                            options={numberOptions}
                            optionLabel="name"
                            placeholder="5"
                          />
                        </div>
                      </li>
                      <li>
                        <span className="sortbytitle">Ordenar por:</span>
                        <div className="sorting-select select-two">
                          <Dropdown
                            value={selectedSort}
                            onChange={(e) => setSelectedSort(e.value)}
                            options={sortOptions}
                            optionLabel="name"
                            placeholder="Más recientes"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="grid-listview">
                    <ul>
                      <li>
                        <a href="#" className="active">
                          <i className="feather icon-grid" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="feather icon-list" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="feather icon-map-pin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortByPlans;