import React from "react";

const FooterBottom = () => {
  return (
    <div className="footer-bottom">
      <div className="container">
        <div className="copyright">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="copyright-text">
                <p>© 2024 CuotaPlan. Todos los derechos reservados.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;