import { stepsTransparency } from "../data/us";

const TransparencyExplanation = () => {
    const steps = stepsTransparency;
//     const steps = [
//     {
//       id: 1,
//       title: '¿De dónde provienen los fondos?',
//       description:
//         'CuotaPlan cobra una comisión por cada plan de ahorro vendido a suscriptores cumplidores. Desde esa comisión, se financian los saldos virtuales, los bonos por referidos y los sorteos.',
//     },
//     {
//       id: 2,
//       title: '¿Qué es un suscriptor cumplidor?',
//       description:
//         'Es aquel que paga al menos 4 cuotas consecutivas de su plan de ahorro. Solo los suscriptores cumplidores pueden acceder a sus saldos virtuales y participar plenamente en los beneficios.',
//     },
//     {
//       id: 3,
//       title: '¿Qué es un referido exitoso?',
//       description:
//         'Un referido que también ha pagado al menos 4 cuotas consecutivas. Los referidores solo obtienen el bono correspondiente si el referido cumple con estas condiciones.',
//     },
//     {
//       id: 4,
//       title: 'Plazos de pago de beneficios',
//       description:
//         'El saldo virtual se habilita 20 días después de cumplir las condiciones mínimas. Los bonos por referidos se otorgan 20 días después de que el referido cumpla con el mínimo de cuotas.',
//     },
//     {
//       id: 5,
//       title: 'Penalizaciones por incumplimiento',
//       description:
//         'Suscriptores y referidos que no cumplan con el mínimo establecido pueden perder puntos para sorteos y no tendrán acceso a beneficios monetarios.',
//     },
//     {
//       id: 6,
//       title: 'Garantía de transparencia',
//       description:
//         'Todos los movimientos de saldo virtual, bonos y puntos están registrados y respaldados, garantizando un sistema sostenible y justo.',
//     },
//   ];

  return (
    <section className="section services bg-light-primary">
      <div className="container">
        {/* Heading title */}
        <div className="section-heading" data-aos="fade-down">
          <h2>Transparencia en CuotaPlan</h2>
          <p>
            Conoce cómo garantizamos un sistema claro y justo para todos nuestros suscriptores y referidos.
          </p>
        </div>
        {/* /Heading title */}
        <div className="services-work">
          <div className="row">
            {steps.map((step) => (
              <div
                key={step.id}
                className="col-lg-4 col-md-6 col-12"
                data-aos="fade-down"
              >
                <div className="services-group">
                  <div className={`services-icon border-secondary`}>
                    <div className={`icon-img bg-secondary`}></div>
                  </div>
                  <div className="services-content">
                    <h3>{step.id}. {step.title}</h3>
                    <p>{step.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TransparencyExplanation;