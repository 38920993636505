import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getMediaByName } from "../../api/wordpress/posts";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { all_routes } from "../router/all_routes";


// function dispatch(arg0: { type: string; payload: any; }) {
//     throw new Error("Function not implemented.");
// }

const Header = () => {
  const routes = all_routes;
  const location = useLocation();

  const mobileSidebar = useSelector((state: any) => state.mobileSidebar);
  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const mediaItems = await getMediaByName('logo-cuotaplan1');
        if (mediaItems.length > 0) {        
          setLogo(mediaItems[0]?.media_details?.sizes?.['full'].source_url);
        }
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []);

//   const dispatch = useDispatch();
  const handleClick = () => {
    // dispatch(set_is_mobile_sidebar(!mobileSidebar));
  };

  const [mobileSubmenu, setMobileSubmenu] = useState(false);

  const mobileSubmenus = () => {
    setMobileSubmenu(!mobileSubmenu);
  };

  return (
    <>
      <header className="header">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <Link id="mobile_btn" to="#" onClick={handleClick}>
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to={routes.homeOne} className="navbar-brand logo">
                {logo ? (
                  <img src={logo} alt="Logo CuotaPlan" className="img-fluid" />
                ) : (
                  <ImageWithBasePath
                    src="assets/img/logo.svg"
                    className="img-fluid"
                    alt="Logo CuotaPlan"
                  />
                )}
              </Link>
              <Link to={routes.homeOne} className="navbar-brand logo-small">
                {logo ? (
                  <img src={logo} alt="Logo CuotaPlan" className="img-fluid" />
                ) : (
                  <ImageWithBasePath
                    src="assets/img/logo-small.png"
                    className="img-fluid"
                    alt="Logo CuotaPlan"
                  />
                )}
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to={routes.homeOne} className="menu-logo">
                  <ImageWithBasePath
                    src="assets/img/logo.svg"
                    className="img-fluid"
                    alt="Logo CuotaPlan"
                  />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#"
                  onClick={handleClick}
                >
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className="main-nav">
                <li
                  className={`has-submenu ${location.pathname.includes("index") ? "active" : ""}`}
                >
                  <Link to="#" onClick={mobileSubmenus}>
                    Inicio <i className="fas fa-chevron-down" />
                  </Link>
                  <ul className={`submenu ${mobileSubmenu ? "d-block" : "d-none"}`}>
                    <li
                      className={location.pathname.includes(routes.homeOne) ? "active" : ""}
                    >
                      <Link to={routes.homeOne}>Vehículos</Link>
                    </li>
                    <li
                      className={location.pathname.includes(routes.homeTwo) ? "active" : ""}
                    >
                      <Link to="#">Botes</Link>
                    </li>
                    <li
                      className={location.pathname.includes(routes.homeThree) ? "active" : ""}
                    >
                      <Link to="#">Motos</Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`has-submenu ${location.pathname.includes("listing") ? "active" : ""}`}
                >
                  <Link to="#">
                    Planes <i className="fas fa-chevron-down" />
                  </Link>
                  <ul className="submenu">
                    <li
                      className={location.pathname.includes(routes.listingGrid) ? "active" : ""}
                    >
                      <Link to={routes.listingGrid}>Planes Disponibles</Link>
                    </li>
                    <li
                      className={location.pathname.includes(routes.listingList) ? "active" : ""}
                    >
                      <Link to={routes.listingList}>Planes</Link>
                    </li>
                    <li
                      className={location.pathname.includes(routes.listingDetails) ? "active" : ""}
                    >
                      <Link to={routes.listingDetails}>Detalles del Plan</Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`has-submenu ${location.pathname.includes("user") ? "active" : ""}`}
                >
                  {/* <Link to="#">
                    Mi Cuenta <i className="fas fa-chevron-down" />
                  </Link>
                  <ul className="submenu">
                    <li
                      className={location.pathname.includes(routes.userDashboard) ? "active" : ""}
                    >
                      <Link to={routes.userDashboard}>Panel</Link>
                    </li>
                    <li
                      className={location.pathname.includes(routes.userBookings) ? "active" : ""}
                    >
                      <Link to={routes.userBookings}>Mis Planes</Link>
                    </li>
                    <li
                      className={location.pathname.includes(routes.userReviews) ? "active" : ""}
                    >
                      <Link to={routes.userReviews}>Opiniones</Link>
                    </li>
                    <li
                      className={location.pathname.includes(routes.userSettings) ? "active" : ""}
                    >
                      <Link to={routes.userSettings}>Configuración</Link>
                    </li>
                  </ul> */}
                </li>
                <li
                  className={location.pathname.includes(routes.contactUs) ? "active" : ""}
                >
                  <Link to={routes.contactUs}>Contacto</Link>
                </li>
                {/* <li className="login-link">
                  <Link to={routes.register}>Registrarse</Link>
                </li>
                <li className="login-link">
                  <Link to={routes.login}>Iniciar Sesión</Link>
                </li> */}
              </ul>
            </div>
            {/* <ul className="nav header-navbar-rht">
              <li className="nav-item">
                <Link className="nav-link header-login" to={routes.login}>
                  <span>
                    <i className="fa-regular fa-user" />
                  </span>
                  Iniciar Sesión
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link header-reg" to={routes.register}>
                  <span>
                    <i className="fa-solid fa-lock" />
                  </span>
                  Registrarse
                </Link>
              </li>
            </ul> */}
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;

