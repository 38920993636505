export const pricingData = [
  {
    id: 1,
    level: "Essential",
    description: "For the basics",
    price: "$49",
    billing: "Per user per month",
    message: "Get Starting message",
    extend: "Min 1 month, extend anytime",
    tax: "Rental car prices include tax",
    return: "Extend or return anytime",
    delivery: "Doorstep delivery in 2 days",
    carsystem: "Car system included free of charge.",
    validity: "Min 1 month, extend anytime",
  },
  {
    id: 2,
    level: "Recommended",
    description: "For the Users",
    price: "$95",
    billing: "Per user per month",
    message: "Get Starting message",
    extend: "Min 1 month, extend anytime",
    tax: "Rental car prices include tax",
    return: "Extend or return anytime",
    delivery: "Doorstep delivery in 2 days",
    carsystem: "Car system included free of charge.",
    validity: "Min 1 month, extend anytime",
  },
  {
    id: 3,
    level: "Pro",
    description: "For the Pro",
    price: "$154",
    billing: "Per user per month",
    message: "Get Starting message",
    extend: "Min 1 month, extend anytime",
    tax: "Rental car prices include tax",
    return: "Extend or return anytime",
    delivery: "Doorstep delivery in 2 days",
    carsystem: "Car system included free of charge.",
    validity: "Min 1 month, extend anytime",
  },
];
