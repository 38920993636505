export const numbers = [
    { name: "5" },
    { name: "10" },
    { name: "15" },
    { name: "20" },
    { name: "25" },
    { name: "30" },
];

export const srot = [
    { name: "Más recientes" },
    { name: "Relevancia" },
    { name: "De menor a mayor precio" },
    { name: "De mayor a menor precio" },
    { name: "Mejor valorados" },
    { name: "Distancia" },
    { name: "Popularidad" },
];

export const numberOptions = [
    { name: "5" },
    { name: "10" },
    { name: "15" },
    { name: "20" },
    { name: "25" },
    { name: "30" },
];

export const sortOptions = [
    { name: "Más recientes" },
    { name: "Relevancia" },
    { name: "De menor a mayor precio" },
    { name: "De mayor a menor precio" },
    { name: "Mejor valorados" },
    { name: "Distancia" },
    { name: "Popularidad" },
];