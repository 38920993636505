import React from "react";
import { Link } from "react-router-dom";

interface FooterMenuProps {
  title: string;
  links: { text: string; to: string }[];
}

const FooterMenu: React.FC<FooterMenuProps> = ({ title, links }) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="footer-widget footer-menu">
        <h5 className="footer-title">{title}</h5>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <Link to={link.to}>{link.text}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FooterMenu;