import React from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";

interface Specification {
    icon: string;
    label: string;
    value: string;
}

interface SpecificationsSectionProps {
    specifications: Specification[];
}



const SpecificationsSection: React.FC<SpecificationsSectionProps> = ({ specifications }) => {
    if (!Array.isArray(specifications) || specifications.length === 0) {
        return null; 
    }   

    return (
        <div className="review-sec specification-card">
            <div className="review-header">
                <h4>Especificaciones del vehiculo</h4>
            </div>
            <div className="card-body">
                <div className="lisiting-featues">
                    <div className="row">
                        {specifications.map((spec, index) => (
                            <div
                                className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6"
                                key={index}
                            >
                                <div className="feature-img">
                                    <ImageWithBasePath src={spec.icon} alt={`Ícono de ${spec.label}`} />
                                </div>
                                <div className="featues-info">
                                    <span>{spec.label}</span>
                                    <h6>{spec.value}</h6>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecificationsSection;
