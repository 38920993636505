// frontend/src/components/VehicleSearchFilters.tsx
import { Dropdown } from "primereact/dropdown";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBrands, getConditions, getModels, getPriceRange } from '../../../api/vehicles/cars';
import { all_routes } from "../../../feature-module/router/all_routes";

interface VehicleSearchFiltersProps {
    type: string;
    selectedModel: string | null;
    setSelectedModel: React.Dispatch<React.SetStateAction<string | null>>;
    selectedBrand: { id: number; name: string } | null;
    setSelectedBrand: React.Dispatch<React.SetStateAction<{ id: number; name: string } | null>>;
    priceRange: { min: number; max: number };
    setPriceRange: (range: { min: number; max: number }) => void;
    location: string;
    setLocation: React.Dispatch<React.SetStateAction<string>>;
    isNew?: boolean;
    setIsNew?: React.Dispatch<React.SetStateAction<boolean>>;
    onPriceChange?: (price: [number, number]) => void;
}

const VehicleSearchFilters: React.FC<VehicleSearchFiltersProps> = ({
    type,
    selectedModel,
    setSelectedModel,
    selectedBrand,
    setSelectedBrand,
    priceRange,
    setPriceRange,
    isNew,
    setIsNew,
    location,
    setLocation,
    onPriceChange
}) => {
    const [models, setModels] = useState<{ id: number; name: string }[]>([]);
    const [brands, setBrands] = useState<{ id: number; name: string }[]>([]);
    const [conditions, setConditions] = useState<{ id: number; name: string }[]>([]);
    const routes = all_routes;
    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const [modelsData, brandsData, conditionsData, priceRangeData] = await Promise.all([
                    getModels(type),
                    getBrands(type),
                    getConditions(type),
                    getPriceRange(type),
                ]);

                setModels(modelsData.map(model => ({ id: model.id, name: model.modelo })));
                setBrands(brandsData.map(brand => ({ id: brand.id, name: brand.nombre, logotipo: brand.logotipo })));
                setConditions(conditionsData);
                setPriceRange(priceRangeData);
            } catch (error) {
                console.error('Error fetching filters:', error);
            }
        };

        fetchFilters();
    }, [type, setPriceRange]);

    const navigate = useNavigate();

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        const queryParams = new URLSearchParams({
            model: selectedModel || '',
            brand: selectedBrand ? selectedBrand.name : '',
            minPrice: priceRange.min.toString(),
            maxPrice: priceRange.max.toString(),
            condition: isNew ? 'new' : 'used',
            location: location || ''
        }).toString();
    
        navigate(`${routes.listingGrid}?${queryParams}`);
    };

    return (
        <div className="section-search">
            <div className="container">
                <div className="search-box-banner">
                    <form onSubmit={handleSearch}>
                        <ul className="align-items-center">
                            {/* Filtro por Modelo */}
                            <li className="column-group-main">
                                <div className="input-block">
                                    <label>Modelo</label>
                                    <Dropdown
                                        value={selectedModel}
                                        options={models}
                                        onChange={(e) => setSelectedModel(e.value)}
                                        optionLabel="name"
                                        placeholder="Selecciona un modelo"
                                        className="w-100"
                                    />
                                </div>
                            </li>

                            {/* Filtro por Marca */}
                            <li className="column-group-main">
                                <div className="input-block">
                                    <label>Marca</label>
                                    <Dropdown
                                        value={selectedBrand}
                                        options={brands}
                                        onChange={(e) => setSelectedBrand(e.value)}
                                        optionLabel="name"
                                        placeholder="Selecciona una marca"
                                        className="w-100"
                                    />
                                </div>
                            </li>

                            {/* Filtro por Rango de Precios */}
                            <li className="column-group-main">
                                <div className="input-block">
                                    <label>Rango de Precios</label>
                                    <Slider
                                        range
                                        min={1}
                                        max={1000000000}
                                        step={1000000}
                                        value={[priceRange.min, priceRange.max]}
                                        onChange={(value) => {
                                            if (Array.isArray(value)) {
                                                onPriceChange && onPriceChange([value[0], value[1]]);
                                            }
                                        }}
                                        className="w-100"
                                    />
                                    <p className="price-range">
                                        {priceRange && (
                                            <>
                                                ${priceRange.min.toLocaleString()} - ${priceRange.max.toLocaleString()}
                                            </>
                                        )}
                                    </p>
                                </div>
                            </li>

                            {/* Filtro por Condición */}
                            <li className="column-group-main">
                                <div className="input-block">
                                    <label>Condición</label>
                                    <Dropdown
                                        value={isNew}
                                        options={conditions}
                                        onChange={(e) => setIsNew && setIsNew(e.value)}
                                        optionLabel="name"
                                        placeholder="Nuevo/Usado"
                                        className="w-100"
                                    />
                                </div>
                            </li>

                            {/* Filtro por Ubicación */}
                            <li className="column-group-main">
                                <div className="input-block">
                                    <label>Ubicación del Concesionario</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        placeholder="Ingresa la ciudad o dirección"
                                    />
                                </div>
                            </li>

                            {/* Botón de Búsqueda */}
                            <li className="column-group-last">
                                <div className="input-block">
                                    <div className="search-btn">
                                        <button className="btn search-button" type="submit">
                                            <i className="fa fa-search" aria-hidden="true" />
                                            Buscar
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default VehicleSearchFilters;
