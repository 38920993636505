import { Link } from "react-router-dom";
import { DetailProductProps } from "../../../../types/cars-types";



const PricingDetails: React.FC<DetailProductProps> = ({ plan }) => {
    if (!plan) {
        return null;
    }

    const planPrice = plan.vehicle.price;
    const virtualBalance = plan.virtualBalanceBonus;
    const referralBonus = plan.referralBonuses;    
    const purefee = Number((plan.vehicle.price / plan.installmentsNumber).toFixed(2));

    return (
        <div className="review-sec mt-0">
            <div className="review-header">
                <h4>Precios</h4>
            </div>
            <div className="price-list">
                <div className="price-item">
                    <p>Precio del plan</p>
                    <h6>${planPrice.toLocaleString()}</h6>
                </div>
                <div className="price-item">
                    <p>Precio cuota pura:</p>
                    <h6><b>${purefee}</b></h6>
                </div>
            </div>
            <div className="price-list">
                <div className="review-header">
                    <h4>Beneficios</h4>
                </div>
                <div className="price-item">
                    <p>Bono Saldo Virtual</p>
                    <h6>${virtualBalance.toLocaleString()}</h6>
                </div>
                <div className="price-item">
                    <p>Bono  cada Referido Exitoso</p>
                    <h6>${referralBonus.toLocaleString()}</h6>
                </div>
                <div className="price-item">
                    <p><strong>Puntos por pago de cuota:</strong></p>
                    <h6>{plan.benefits.ticketsPerInstallment}</h6>
                </div>
                <div className="price-item">
                    <p><strong>Puntos por referidos exitosos:</strong></p>
                    <h6>{plan.benefits.ticketsPerReferral}</h6>
                </div>
                <div className="price-item">
                    <p><strong>Penalización por incumplimiento:</strong></p>
                    <h6>{plan.benefits.penaltyForNonCompliance} puntos</h6>
                </div>
                </div>
            <div className="price-list">
                <div className="review-header">
                    <h4>Sorteos y Premios vigentes</h4>
                </div>                                
                {plan.activeDraws.map((draw, index) => (
                    <div className="price-item" key={index}>
                        <p>
                            <strong>{draw.nombre}:</strong> {draw.premio} - <strong>{draw.boletos_necesarios} Puntos</strong> -
                            <span> Fecha: {new Date(draw.fecha).toLocaleDateString()}</span>
                        </p>
                    </div>
                ))}                
            </div>
            <div className="text-end">
                <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#fare_details"
                    className="fare-link"
                >
                    <i className="feather-file-text" />
                    Resumen de Detalles
                </Link>
            </div>
        </div>      
    );
};

export default PricingDetails;
