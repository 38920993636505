import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";

interface ReviewsSectionProps {

    reviews: any[];

}



const ReviewsSection: React.FC<ReviewsSectionProps> = ({ reviews }) => {
    if (!Array.isArray(reviews) || reviews.length === 0) {
        return null;
    }

    // const reviews = [
    //     {
    //         name: "Johnson",
    //         date: "02 Ene 2023",
    //         rating: 5.0,
    //         comment:
    //             "Se popularizó en la década de 1960 con el lanzamiento de hojas de Letraset que contenían pasajes de Lorem Ipsum.",
    //         avatar: "assets/img/profiles/avatar-01.jpg",
    //         likes: 10,
    //         dislikes: 12,
    //         favorites: 15,
    //     },
    //     {
    //         name: "Casandra",
    //         date: "25 Mar 2024",
    //         rating: 5.0,
    //         comment:
    //             "Ofrecemos una flota diversa de vehículos para satisfacer todas las necesidades, incluyendo compactos, sedanes, SUVs y autos de lujo.",
    //         avatar: "assets/img/profiles/avatar-02.jpg",
    //         likes: 8,
    //         dislikes: 5,
    //         favorites: 20,
    //     },
    // ];

    return (
        <div className="review-sec listing-review">
            <div className="review-header">
                <h4>Reseñas</h4>
            </div>
            <div className="rating-wrapper">
                <div className="rating-wraps">
                    <h2>
                        4.5<span>/5</span>
                    </h2>
                    <p>Excelente</p>
                    <h6>Basado en 256 Reseñas</h6>
                </div>
                <div className="rating-progress">
                    <div className="progress-info">
                        <h6>Servicio</h6>
                        <div className="progress" role="progressbar">
                            <div className="progress-bar bg-primary" style={{ width: "70%" }} />
                        </div>
                        <div className="progress-percent">4.6</div>
                    </div>
                    <div className="progress-info">
                        <h6>Ubicación</h6>
                        <div className="progress" role="progressbar">
                            <div className="progress-bar bg-primary" style={{ width: "85%" }} />
                        </div>
                        <div className="progress-percent">4.8</div>
                    </div>
                    <div className="progress-info">
                        <h6>Relación Calidad/Precio</h6>
                        <div className="progress" role="progressbar">
                            <div className="progress-bar bg-primary" style={{ width: "60%" }} />
                        </div>
                        <div className="progress-percent">3.0</div>
                    </div>
                    <div className="progress-info">
                        <h6>Instalaciones</h6>
                        <div className="progress" role="progressbar">
                            <div className="progress-bar bg-primary" style={{ width: "65%" }} />
                        </div>
                        <div className="progress-percent">4.5</div>
                    </div>
                    <div className="progress-info">
                        <h6>Limpieza</h6>
                        <div className="progress" role="progressbar">
                            <div className="progress-bar bg-primary" style={{ width: "90%" }} />
                        </div>
                        <div className="progress-percent">4.8</div>
                    </div>
                </div>
            </div>
            <div className="review-card">
                <div className="review-head">
                    <h6>Mostrando 2 reseñas</h6>
                </div>
                <ul>
                    {reviews.map((review, index) => (
                        <li key={index}>
                            <div className="review-wraps">
                                <div className="review-header-group">
                                    <div className="review-widget-header">
                                        <span className="review-widget-img">
                                            <ImageWithBasePath
                                                src={review.avatar}
                                                className="img-fluid"
                                                alt={review.name}
                                            />
                                        </span>
                                        <div className="review-design">
                                            <h6>{review.name}</h6>
                                            <p>{review.date}</p>
                                        </div>
                                    </div>
                                    <div className="reviewbox-list-rating">
                                        <p>
                                            {Array.from({ length: Math.floor(review.rating) }).map(
                                                (_, starIndex) => (
                                                    <i className="fas fa-star filled" key={starIndex} />
                                                )
                                            )}
                                            <span> ({review.rating})</span>
                                        </p>
                                    </div>
                                </div>
                                <p>{review.comment}</p>
                                <div className="review-reply">
                                    <Link className="btn" to="#">
                                        <i className="fa-solid fa-reply" />
                                        Responder
                                    </Link>
                                    <div className="review-action">
                                        <Link to="#">
                                            <i className="fa-regular fa-thumbs-up" />
                                            {review.likes}
                                        </Link>
                                        <Link to="#">
                                            <i className="fa-regular fa-thumbs-down" />
                                            {review.dislikes}
                                        </Link>
                                        <Link to="#">
                                            <i className="fa-regular fa-heart" />
                                            {review.favorites}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ReviewsSection;
