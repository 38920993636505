import { Dayjs } from "dayjs";
import { registerGAEvent } from "./google";

// src/utils.ts
interface StripTags {
    (html: string): string;
}
export const stripTags = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
};

export const onChangeDayjs = (time: Dayjs, timeString: string | string[]) => {
    console.log(time, timeString);
};


export const setPageTitleAndRegisterGAEvent = (
    title: string,
    category: string,
    action: string,
    value?: number
    
): void => {
    // const pageTitle = `${label} - ${category} - ${action}`;
    const pageTitle = `${title}`;
    registerGAEvent(category, action, title,value);
    document.title = pageTitle;
};
