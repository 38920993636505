import React from "react";
import FooterBottom from "../../components/cars/footer/FooterBottom";
import FooterTop from "../../components/cars/footer/FooterTop";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <FooterTop />
        <FooterBottom />
      </footer>
    </>
  );
};

export default Footer;