export const commonSliderSettings = {
    dots: true,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 0,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

export const getSliderSettings = (itemsLength: number) => ({
    dots: true,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: itemsLength > 3 ? 3 : itemsLength, // Determina dinámicamente cuántos elementos mostrar
    slidesToScroll: 1,
    arrows: true,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: Math.min(itemsLength, 3), // Ajusta dinámicamente
            },
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: Math.min(itemsLength, 2),
            },
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: Math.min(itemsLength, 1),
            },
        },
        {
            breakpoint: 0,
            settings: {
                slidesToShow: Math.min(itemsLength, 1),
            },
        },
    ],
});