import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { all_routes } from "../../router/all_routes";

const Error404 = () => {
  const routes = all_routes;
  return (
    <div className="main-wrapper">
      <div className="error-box">
        <ImageWithBasePath
          src="assets/img/404.png"
          className="img-fluid"
          alt="Página no encontrada"
        />
        <h3>Página no encontrada</h3>
        <p>La página que solicitaste no fue encontrada.</p>
        <div className="back-button">
          <Link to={routes.homeOne} className="btn btn-primary">
            Volver al Inicio
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error404;
