import { Link } from "react-router-dom";
import { faqTransparencyData } from "../../../components/cars/data/us";
import { all_routes } from "../../router/all_routes";

const FAQTransparency = () => {
    const routes = all_routes
    return (
        <>
            {/* Breadscrumb */}
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">FAQ</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={routes.homeOne}>Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#">Pages</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        FAQ
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Breadscrumb */}

            {/* FAQ Section */}
            <section className="section faq-section">
                <div className="container">
                    <div className="faq-info">
                        {faqTransparencyData.map((faq, index) => (
                            <div
                                className="faq-card bg-white"
                                data-aos="fade-down"
                                key={index}
                            >
                                <h4 className="faq-title">
                                    <Link
                                        className="collapsed"
                                        data-bs-toggle="collapse"
                                        to={`#faq${index}`}
                                        aria-expanded="false"
                                    >
                                        {faq.question}
                                    </Link>
                                </h4>
                                <div id={`faq${index}`} className="card-collapse collapse">
                                    <p>{faq.answer}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* /FAQ Section */}
        </>
    );
};

export default FAQTransparency;