import React, { useState } from "react";
import { Link } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";

interface GallerySectionProps {

    images: any[];

}



const GallerySection: React.FC<GallerySectionProps> = ({ images }) => {
    if (!Array.isArray(images) || images.length === 0) {
        return null;
    }

    const [open, setOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    // const images = [
    //     {
    //         thumb: "assets/img/gallery/gallery-thumb-01.jpg",
    //         big: "assets/img/gallery/gallery-big-01.jpg",
    //     },
    //     {
    //         thumb: "assets/img/gallery/gallery-thumb-02.jpg",
    //         big: "assets/img/gallery/gallery-big-02.jpg",
    //     },
    //     {
    //         thumb: "assets/img/gallery/gallery-thumb-03.jpg",
    //         big: "assets/img/gallery/gallery-big-03.jpg",
    //     },
    //     {
    //         thumb: "assets/img/gallery/gallery-thumb-04.jpg",
    //         big: "assets/img/gallery/gallery-big-04.jpg",
    //     },
    // ];

    const openLightbox = (index: number) => {
        setCurrentIndex(index);
        setOpen(true);
    };

    return (
        <div className="review-sec mb-0 pb-0">
            <div className="review-header">
                <h4>Galería</h4>
            </div>
            <div className="gallery-list">
                <div className="d-flex">
                    <Lightbox
                        open={open}
                        close={() => setOpen(false)}
                        slides={images.map((image) => ({ src: image.big }))}
                        index={currentIndex}
                    />
                    {images.map((image, index) => (
                        <div key={index}>
                            <ul>
                                <li>
                                    <div className="gallery-widget">
                                        <Link
                                            to="#"
                                            data-fancybox="gallery"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openLightbox(index);
                                            }}
                                        >
                                            <ImageWithBasePath
                                                className="img-fluid"
                                                alt={`Imagen ${index + 1}`}
                                                src={image.thumb}
                                            />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GallerySection;
