import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { brandsWithLogos, mostPopularCars } from "../data/cars";


const ExploreMostPopularCars: React.FC = () => {    
    

    return (
        <section className="section popular-services popular-explore">
            <div className="container">
                {/* Título */}
                <div className="section-heading" data-aos="fade-down">
                    <h2>Explora los Autos Más Populares</h2>
                    <p>Descubre los autos más buscados que se adaptan a tus necesidades.</p>
                </div>
                {/* /Título */}

                {/* Sección de Marcas */}
                <div className="row justify-content-center">
                    <div className="col-lg-12" data-aos="fade-down">
                        <div className="listing-tabs-group">
                            <ul className="nav listing-buttons gap-3" data-bs-tabs="tabs">
                                {brandsWithLogos.map((brand) => (
                                    <li key={brand.id}>
                                        <Link
                                            // className="active"
                                            aria-current="true"
                                            data-bs-toggle="tab"
                                            to={`#Car${brand.name}`}
                                        >
                                            <span>
                                                <ImageWithBasePath
                                                    src={brand.icon}
                                                    alt={brand.name}
                                                />
                                            </span>
                                            {brand.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Sección de Autos */}
                <div className="tab-content">
                    {brandsWithLogos.map((brand) => (
                        <div
                            key={brand.id}
                            className={`tab-pane ${brand.id === 1 ? "active" : ""
                                }`}
                            id={`Car${brand.name}`}
                        >
                            <div className="row">
                                {mostPopularCars
                                    //   .filter((car) => car.brand === brand.name)
                                    .map((car) => (
                                        <div
                                            key={car.id}
                                            className="col-lg-4 col-md-6 col-12"
                                            data-aos="fade-down"
                                        >
                                            <div className="listing-item">
                                                <div className="listing-img">
                                                    <Link to="#">
                                                        <ImageWithBasePath
                                                            src={car.image}
                                                            className="img-fluid"
                                                            alt={car.name}
                                                        />
                                                    </Link>
                                                    <span className="featured-text">{car.brand}</span>
                                                </div>
                                                <div className="listing-content">
                                                    <div className="listing-features d-flex align-items-end justify-content-between">
                                                        <div className="list-rating">
                                                            <h3 className="listing-title">
                                                                <Link to="#">{car.name}</Link>
                                                            </h3>
                                                            <div className="list-rating">
                                                                {[...Array(Math.floor(car.rating))].map(
                                                                    (_, index) => (
                                                                        <i
                                                                            key={index}
                                                                            className="fas fa-star filled"
                                                                        />
                                                                    )
                                                                )}
                                                                {car.rating % 1 !== 0 && (
                                                                    <i className="fas fa-star-half-alt filled" />
                                                                )}
                                                                <span>
                                                                    ({car.rating}) {car.reviews} Opiniones
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="listing-details-group">
                                                        <ul>
                                                            <li>

                                                                <p><span>
                                                                    <ImageWithBasePath
                                                                        src="assets/img/icons/fuel.svg"
                                                                        className="img-fluid"
                                                                        alt={car.fuel}
                                                                    />
                                                                </span>{car.fuel}</p>
                                                            </li>
                                                            <li>

                                                                <p><span>
                                                                    <ImageWithBasePath
                                                                        src="assets/img/icons/lever.svg"
                                                                        className="img-fluid"
                                                                        alt={car.transmission}
                                                                    />
                                                                </span>{car.transmission}</p>
                                                            </li>
                                                            <li>

                                                                <p><span>
                                                                    <ImageWithBasePath
                                                                        src="assets/img/icons/motor.svg"
                                                                        className="img-fluid"
                                                                        alt={`${car.year}`}
                                                                    />
                                                                </span>{car.year}</p>
                                                            </li>
                                                            <li>

                                                                <p><span>
                                                                    <ImageWithBasePath
                                                                        src="assets/img/icons/mileage.svg"
                                                                        className="img-fluid"
                                                                        alt={car.mileage}
                                                                    />
                                                                </span>{car.mileage}</p>
                                                            </li>
                                                            <li>

                                                                <p><span>
                                                                    <ImageWithBasePath
                                                                        src="assets/img/icons/passengers.svg"
                                                                        className="img-fluid"
                                                                        alt={`${car.passengers}`}
                                                                    />
                                                                </span>{car.passengers}</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="listing-location-details">
                                                        <div className="listing-price">
                                                            <h6>
                                                                ${car.price}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="listing-button">
                                                        <Link to="#" className="btn btn-order">
                                                            Ver plan
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ExploreMostPopularCars;