import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';
import { steps } from '../data/us';

const HowItWorks = () => {
  

  return (
    <section className="section services bg-light-primary">
      <div className="container">
        {/* Heading title */}
        <div className="section-heading" data-aos="fade-down">
          <h2>¿Cómo funciona CuotaPlan?</h2>
          <p>
            Descubre cómo puedes alcanzar tu próximo vehículo de manera sencilla, rentable y colaborativa.
          </p>
        </div>
        {/* /Heading title */}
        <div className="services-work">
          <div className="row">
            {steps.map((step) => (
              <div
                key={step.id}
                className="col-lg-4 col-md-4 col-12"
                data-aos="fade-down"
              >
                <div className="services-group">
                  <div className={`services-icon ${step.borderClass}`}>
                    <ImageWithBasePath
                      className={`icon-img ${step.bgClass}`}
                      src={step.image}
                      alt={step.title}
                    />
                  </div>
                  <div className="services-content">
                    <h3>{step.id}. {step.title}</h3>
                    <p>{step.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;