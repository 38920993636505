import Aos from "aos";
import type { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "yet-another-react-lightbox/styles.css";
import { getPlanByModel } from "../../api/vehicles/cars";
import DescriptionSection from "../../components/cars/detail/DescriptionSection";
import DetailHeader from "../../components/cars/detail/DetailHeader";
import DetailProduct from "../../components/cars/detail/DetailProduct";
import GallerySection from "../../components/cars/detail/GallerySection";
import ReviewsSection from "../../components/cars/detail/ReviewsSection";
import SpecificationsSection from "../../components/cars/detail/SpecificationsSection";
import DealerDetails from "../../components/cars/detail/stickybar/DealerDetails";
import InterestedCars from "../../components/cars/detail/stickybar/InterestedCars";
import PricingDetails from "../../components/cars/detail/stickybar/PricingDetails";
import ShareSection from "../../components/cars/detail/stickybar/ShareSection";
import { setPageTitleAndRegisterGAEvent } from "../../utils";
import Breadcrumbs from "../common/breadcrumbs";
import { all_routes } from "../router/all_routes";

const ListingDetails = () => {
    const routes = all_routes;
    const location = useLocation();
    const navigate = useNavigate();
    const [plan, setPlan] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true); // Estado de carga
    const [date1, setDate1] = useState(null);
    const bigImgSliderRef = useRef<Slider | null>(null);
    const thumbnailSliderRef = useRef<Slider | null>(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedLocation1, setSelectedLocation1] = useState(null);
    const [date2, setDate2] = useState(null);    
    const [open, setOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
   
    const onChange = (time: Dayjs, timeString: string) => {
        console.log(time, timeString);
    };
    const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
    
    const handleItemClick = (index: number) => {
        setSelectedItems((prevSelectedItems) => {
            const updatedSelectedItems = [...prevSelectedItems];
            updatedSelectedItems[index] = !updatedSelectedItems[index];
            return updatedSelectedItems;
        });
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const model = queryParams.get('model');
        console.log('Model:', model);
        if (model) {
            console.log('Model:', model);
            const fetchPlan = async () => {
                try {
                    const planData = await getPlanByModel(model);
                    if (!planData) {
                        navigate(routes.error404);
                    } else {
                        setPlan(planData);
                        setPageTitleAndRegisterGAEvent(`${planData.name} - CuotaPlan`, "Planes", "Visita", 1);
                    }
                } catch (error) {
                    console.error('Error fetching plan by model:', error);
                    navigate(routes.error404);
                } finally {
                    setLoading(false); // Finalizar la carga
                }
            };

            fetchPlan();
        } else {
            navigate(routes.error404);
        }
    }, [location.search, history, routes.error404]);

    useEffect(() => {
        if (bigImgSliderRef.current && thumbnailSliderRef.current) {
            bigImgSliderRef.current.slickGoTo(0);
            thumbnailSliderRef.current.slickGoTo(0);
        }
    }, []);
    useEffect(() => {
        Aos.init({ duration: 1200, once: true });
    }, []);
    
    const openLightbox = (index: number): void => {
        setCurrentIndex(index);
        setOpen(true);
    };
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const sliderRef1 = useRef(null);
    const sliderRef2 = useRef(null);

    useEffect(() => {
        setNav1(sliderRef1.current);
        setNav2(sliderRef2.current);
    }, []);

    const settings1 = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: nav2 || undefined, // Link to the second slider
        ref: (slider: any) => (sliderRef1.current = slider), // Assign the slider ref

    };

    const settings2 = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        asNavFor: nav1 || undefined, // Link to the first slider
        ref: (slider: any) => (sliderRef2.current = slider), // Assign the slider ref

    };

    if (loading) {
        return <div>Loading...</div>; // Mostrar un mensaje de carga mientras se obtienen los datos
    }

    return (
        <div className="main-wrapper">
            <Breadcrumbs title={plan ? plan.name : "Plan"} subtitle="Planes" subtitleLink={routes.listingGrid} />
            <DetailHeader plan={plan ? plan : {}} />
            <section className="section product-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <DetailProduct plan={plan} />
                            <DescriptionSection description={plan ? plan.description : ""} />
                            <SpecificationsSection specifications={plan ? plan.reviews : []} />
                            <GallerySection images={plan ? plan.images : []} />
                            <ReviewsSection reviews={plan ? plan.reviews : []} />
                        </div>
                        <div className="col-lg-4 theiaStickySidebar">
                            <div className="stickybar">
                                <PricingDetails plan={plan} />
                                <DealerDetails dealer={plan ? plan.dealer : {}} />
                                {/* <CarLocationView address={plan ? plan.dealer.fullAddress : ""} /> */}
                                <ShareSection plan={plan} />
                            </div>
                        </div>
                    </div>
                    <InterestedCars />
                </div>
            </section>
            <div
                className="modal custom-modal fade check-availability-modal"
                id="pages_edit"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="form-header text-start mb-0">
                                <h4 className="mb-0 text-dark fw-bold">Availability Details</h4>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span className="align-center" aria-hidden="true">
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="available-for-ride">
                                        <p>
                                            <i className="fa-regular fa-circle-check" />
                                            Chevrolet Camaro is available for a ride
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="row booking-info">
                                        <div className="col-md-4 pickup-address">
                                            <h5>Pickup</h5>
                                            <p>45, 4th Avanue Mark Street USA</p>
                                            <span>Date &amp; time : 11 Jan 2023</span>
                                        </div>
                                        <div className="col-md-4 drop-address">
                                            <h5>Drop Off</h5>
                                            <p>78, 10th street Laplace USA</p>
                                            <span>Date &amp; time : 11 Jan 2023</span>
                                        </div>
                                        <div className="col-md-4 booking-amount">
                                            <h5>Booking Amount</h5>
                                            <h6>
                                                <span>$300 </span> /day
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="booking-info seat-select">
                                        <h6>Extra Service</h6>
                                        <label className="custom_check">
                                            <input
                                                type="checkbox"
                                                name="rememberme"
                                                className="rememberme"
                                            />
                                            <span className="checkmark" />
                                            Baby Seat - <span className="ms-2">$10</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="booking-info pay-amount">
                                        <h6>Deposit Option</h6>
                                        <div className="radio radio-btn">
                                            <label>
                                                <input type="radio" name="radio" /> Pay Deposit
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input type="radio" name="radio" /> Full Amount
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" />
                                <div className="col-md-6">
                                    <div className="booking-info service-tax">
                                        <ul>
                                            <li>
                                                Booking Price <span>$300</span>
                                            </li>
                                            <li>
                                                Extra Service <span>$10</span>
                                            </li>
                                            <li>
                                                Tax <span>$5</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="grand-total">
                                        <h5>Grand Total</h5>
                                        <span>$315</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Link to={routes.booking} className="btn btn-back">
                                Go to Details
                                <i className="fa-solid fa-arrow-right" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingDetails;
