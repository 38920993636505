import React from "react";
import { Link } from "react-router-dom";
import { Plan } from "../../../../types/cars-types";

interface ShareSectionProps {
    plan: Plan;
    inviteText?: string;
}

const ShareSection: React.FC<ShareSectionProps> = (
    { plan,
        inviteText = "Únete a nuestro plan de ahorro y asegura tu futuro." }
) => {

    if (!plan) {
        return null;
    }

    const encodedPlanName = encodeURIComponent(plan.name);
    const encodedInviteText = encodeURIComponent(inviteText);
    const baseUrl = window.location.href;

    const socialLinks = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${baseUrl}&quote=${encodedInviteText} - ${encodedPlanName}`,
        twitter: `https://twitter.com/intent/tweet?text=${encodedInviteText}&url=${baseUrl}&hashtags=CuotaPlan,Ahorro`,
        linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${baseUrl}&title=${encodedPlanName}&summary=${encodedInviteText}`,
        whatsapp: `https://wa.me/?text=${encodedInviteText} - ${encodedPlanName} ${baseUrl}`,
        pinterest: `https://pinterest.com/pin/create/button/?url=${baseUrl}&description=${encodedInviteText} - ${encodedPlanName}`,
    };
    return (
        <div className="review-sec share-car mt-0 mb-0">
            <div className="review-header">
                <h4>Compartir</h4>
            </div>
            <ul className="nav-social">
                <li>
                    <Link to={socialLinks.facebook}>
                        <i className="fa-brands fa-facebook-f fi-icon" />
                    </Link>
                </li>
                {/* <li>
                    <Link to="#">
                        <i className="fab fa-instagram fi-icon" />
                    </Link>
                </li> */}
                {/* <li>
                    <Link to="#">
                        <i className="fab fa-behance fi-icon" />
                    </Link>
                </li> */}
                <li>
                    <Link to={socialLinks.pinterest}>
                        <i className="fa-brands fa-pinterest-p fi-icon" />
                    </Link>
                </li>
                <li>
                    <Link to={socialLinks.twitter}>
                        <i className="fab fa-twitter fi-icon" />
                    </Link>
                </li>
                <li>
                    <Link to={socialLinks.linkedin}>
                        <i className="fab fa-linkedin fi-icon" />
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default ShareSection;