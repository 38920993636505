import React, { useEffect, useState } from 'react';
import { getMediaByName } from '../../../api/wordpress/posts';

interface ImageWithBasePathProps {
  src: string;
  alt?: string;
  className?: string;
  height?: number | string;
  width?: number | string;
  id?: string;
  isWordPress?: boolean; // Nuevo parámetro booleano
}

const ImageWithBasePath: React.FC<ImageWithBasePathProps> = ({
  src,
  alt = "image",
  className,
  height,
  width,
  id,
  isWordPress = false, // Valor por defecto
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (!src) {
        console.error("Image source is undefined");
        return;
      }

      if (isWordPress) {
        const imageData = await getMediaByName(src);        
          try {
            if (imageData && imageData[0]?.media_details?.sizes?.['full']) {
                setImageUrl(imageData[0].media_details.sizes['full'].source_url);
            } else {
                console.error('Error: No suitable banner image found.');
            }            
          } catch (error) {
            console.error("Error fetching media from WordPress:", error);
          }        
      } else {
        const isExternal = src.startsWith("http://") || src.startsWith("https://");
        const fullSrc = isExternal
          ? src
          : `${process.env.REACT_APP_IMAGE_PATH || "/"}${src}`;
        setImageUrl(fullSrc);
      }
    };

    fetchImageUrl();
  }, [src, isWordPress]);

  if (!imageUrl) {
    return null; // O puedes devolver una imagen de placeholder
  }

  return (
    <img
      className={className}
      src={imageUrl}
      height={height}
      alt={alt}
      width={width}
      id={id}
    />
  );
};

export default ImageWithBasePath;