// src/api/wordpress/posts.ts
import wordpressAPI from './index';

export const getPosts = async () => {
    try {
        const response = await wordpressAPI.get('/posts');
        return response.data;
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

export const getPostById = async (id: number) => {
    try {
        const response = await wordpressAPI.get(`/posts/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching post with id ${id}:`, error);
        throw error;
    }
};

export const getPostsByCategory = async (categoryId: number) => {
    try {
        const response = await wordpressAPI.get('/posts', {
            params: { categories: categoryId },
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching posts for category id ${categoryId}:`, error);
        throw error;
    }
};

export const getPostBySlug = async (slug: string) => {
    try {
        const response = await wordpressAPI.get(`/posts`, {
            params: { slug },
        });
        return response.data.length ? response.data[0] : null;
    } catch (error) {
        console.error(`Error fetching post with slug ${slug}:`, error);
        throw error;
    }
};


export const getPostsByCategorySlug = async (slug: string) => {
    try {
        // Obtén las categorías para encontrar la ID de la categoría con el slug proporcionado
        const categoriesResponse = await wordpressAPI.get('/categories', {
            params: { slug },
        });

        const category = categoriesResponse.data.length ? categoriesResponse.data[0] : null;

        if (!category) throw new Error(`Category with slug "${slug}" not found`);

        // Obtén los posts de la categoría
        const postsResponse = await wordpressAPI.get('/posts', {
            params: { categories: category.id },
        });

        return postsResponse.data;
    } catch (error) {
        console.error(`Error fetching posts for category "${slug}":`, error);
        throw error;
    }

};


// Obtiene todas las imágenes desde la API de WordPress
export const getMedia = async () => {
    try {
        const response = await wordpressAPI.get('/media');
        return response.data;
    } catch (error) {
        console.error('Error fetching media:', error);
        throw error;
    }
};

interface MediaItem {
    id: number;
    date: string;
    date_gmt: string;
    guid: {
        rendered: string;
    };
    modified: string;
    modified_gmt: string;
    slug: string;
    status: string;
    type: string;
    link: string;
    title: {
        rendered: string;
    };
    author: number;
    featured_media: number;
    comment_status: string;
    ping_status: string;
    template: string;
    meta: any[];
    class_list: string[];
    description: {
        rendered: string;
    };
    caption: {
        rendered: string;
    };
    alt_text: string;
    media_type: string;
    mime_type: string;
    media_details: {
        width: number;
        height: number;
        file: string;
        filesize: number;
        mime_type: string;
        sizes: Record<string, any>;
        image_meta: {
            aperture?: number;
            credit?: string;
            camera?: string;
            caption?: string;
            created_timestamp?: string;
            copyright?: string;
            focal_length?: number;
            iso?: number;
            shutter_speed?: string;
            title?: string;
            orientation?: number;
            keywords?: any[];
        };
    };
    post: any | null;
    source_url: string;
    _links: {
        self: Array<{
            href: string;
            targetHints?: {
                allow: string[];
            };
        }>;
        collection: Array<{ href: string }>;
        about: Array<{ href: string }>;
        author: Array<{
            embeddable: boolean;
            href: string;
        }>;
        replies: Array<{
            embeddable: boolean;
            href: string;
        }>;
    };
}


// Refactorización de getMediaById para obtener imágenes
export const getMediaById = async (id: number) => {
    try {
        // Usa la API de WordPress para obtener la información de la imagen
        const response = await wordpressAPI.get(`/media/${id}`);
        if (response.data) {
            return {
                id: response.data.id,
                url: response.data.source_url,
                title: response.data.title.rendered,
                description: response.data.description.rendered || '',
                caption: response.data.caption.rendered || '',
            };
        } else {
            throw new Error(`No media found with id: ${id}`);
        }
    } catch (error) {
        console.error(`Error fetching media with id ${id}:`, error);
        throw error;
    }
};

// Obtiene las imágenes asociadas a un post específico
export const getMediaByPostId = async (postId: number) => {
    try {
        const response = await wordpressAPI.get('/media', {
            params: { parent: postId },
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching media for post id ${postId}:`, error);
        throw error;
    }
};

// Obtiene las imágenes asociadas por tipo MIME
export const getMediaByMimeType = async (mimeType: string) => {
    try {
        const response = await wordpressAPI.get('/media', {
            params: { mime_type: mimeType },
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching media with mime type ${mimeType}:`, error);
        throw error;
    }
};

// Obtiene las imágenes asociadas por nombre (título del medio)
export const getMediaByName = async (name: string): Promise<MediaItem[]> => {
    try {
        const response = await wordpressAPI.get('/media', {
            params: { search: name },
        });

        if (response.data.length === 0) {
            console.log(`No media found with name: ${name}`);
            // throw new Error('No media found with name');
        }

        return response.data.map((item: any): MediaItem => ({
            id: item.id,
            date: item.date,
            date_gmt: item.date_gmt,
            guid: item.guid,
            modified: item.modified,
            modified_gmt: item.modified_gmt,
            slug: item.slug,
            status: item.status,
            type: item.type,
            link: item.link,
            title: item.title,
            author: item.author,
            featured_media: item.featured_media,
            comment_status: item.comment_status,
            ping_status: item.ping_status,
            template: item.template,
            meta: item.meta,
            class_list: item.class_list,
            description: item.description,
            caption: item.caption,
            alt_text: item.alt_text,
            media_type: item.media_type,
            mime_type: item.mime_type,
            media_details: item.media_details,
            post: item.post,
            source_url: item.source_url,
            _links: item._links,
        }));
    } catch (error) {
        console.error(`Error fetching media with name "${name}":`, error);
        throw error;
    }
};

// Función para obtener medios por nombre
export const getMediaByNameSize = async (slug: string, size: string = "full") => {
    try {
      const response = await wordpressAPI.get(`/media?slug=${slug}`);
      const media = response.data[0];
  
      // Verificar si existe el tamaño solicitado
      if (media?.media_details?.sizes && media.media_details.sizes[size]) {
        return media.media_details.sizes[size].source_url;
      }
  
      // Si no se encuentra el tamaño solicitado, retornar el tamaño completo
      return media?.source_url || null;
    } catch (error) {
      console.error("Error fetching media by name:", error);
      return null;
    }
  };

  export const getMediaUrl = async (slug: string, size: string = "full") => {
    try {
      const imageUrl = await getMediaByNameSize(slug, size);
      if (imageUrl) {
        console.log(`Fetched image (${size}) for slug "${slug}":`, imageUrl);
      } else {
        console.warn(`No image found for slug "${slug}" with size "${size}".`);
      }
      return imageUrl;
    } catch (error) {
      console.error("Error in fetchImage:", error);
      return null;
    }
  }; 


