import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { all_routes } from "../../feature-module/router/all_routes";
import { CarGridViewProps, Plan } from "../../types/cars-types";
import { commonSliderSettings, getSliderSettings } from "./data/sliderSettings";

const CarGridView: React.FC<CarGridViewProps> = ({
    plans,
    brands,
    categories,
    priceRange,
    selectedBrands,
    selectedCategories,
    selectedRatings,
    selectedRecommendations,
    onBrandChange,
    onCategoryChange,
    onPriceChange,
    onRatingChange,
    onRecommendationChange,
    currentPage,
    totalPages,
    onPageChange,
    location,
}) => {
    const routes = all_routes;

    const modifiedSliderSettings = {
        ...commonSliderSettings,
        slidesToShow: commonSliderSettings.slidesToShow + 1,
    };

    const handleListingDetails = (car: Plan) => {
        const params = {
            model: car.vehicle.model,
            // priceRange: priceRange.join(','),
            // type: car.type,
            // brand: car.brand,
            // location: car.location,
        };
        const link = `${routes.listingDetails}?${new URLSearchParams(params).toString()}`
        return link;
    };

    return (
        <section className="section car-listing pt-0">
            <div className="container">
                <div className="row">
                    {/* Sidebar Filters */}
                    {/* <MasterListing
            brands={brands}
            categories={categories}
            priceRange={priceRange}
            selectedBrands={selectedBrands}
            selectedCategories={selectedCategories}
            selectedRatings={selectedRatings}
            selectedRecommendations={selectedRecommendations}
            onBrandChange={onBrandChange}
            onCategoryChange={onCategoryChange}
            onPriceChange={onPriceChange}
            onRatingChange={onRatingChange}
            onRecommendationChange={onRecommendationChange}
          /> */}

                    {/* Cars Grid */}
                    <div className="col-lg-9">
                        <div className="row">
                            {plans.map((plan) => (
                                <div
                                    className="col-xxl-4 col-lg-6 col-md-6 col-12"
                                    key={plan.id}
                                >
                                    <div className="listing-item">
                                        {/* Car Images */}
                                        <div className="listing-img">
                                            <div className="img-slider listing-page-slider">
                                                <Slider
                                                    {...getSliderSettings}
                                                    afterChange={(current) => {
                                                        if (typeof current === "number") {
                                                            console.log("Current slide index:", current.toFixed(2)); // Solo llamar a `toFixed` si es un número
                                                        } else {
                                                            console.warn("Expected a number but received:", current);
                                                        }
                                                    }}
                                                >
                                                    {plan.images.map((image, index) => (
                                                        <div key={index}>
                                                            <ImageWithBasePath
                                                                src={image}
                                                                alt={plan.name}
                                                                isWordPress={true}
                                                            />
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                            <span className="featured-text">{plan.vehicle.brand}</span>
                                        </div>


                                        {/* Car Content */}
                                        <div className="listing-content">
                                            <div className="listing-features d-flex align-items-end justify-content-between">
                                                <div className="list-rating">
                                                    <h3 className="listing-title">
                                                        <Link to={handleListingDetails(plan)}>{plan.name}</Link>
                                                    </h3>
                                                    <div className="list-rating">
                                                        {Array.from({ length: 5 }, (_, i) => (
                                                            <i
                                                                key={i}
                                                                className={`fas fa-star ${i < plan.rating ? "filled" : ""
                                                                    }`}
                                                            />
                                                        ))}
                                                        <span>
                                                            ({plan.rating}.0) {plan.reviewsCount} Reseñas
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="list-km">
                                                    <span className="km-count">
                                                        <ImageWithBasePath
                                                            src="assets/img/icons/map-pin.svg"
                                                            alt="location"
                                                        />
                                                        {plan.dealer.fullAddress}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feature-text">
                                                {plan.featureText.featured && (
                                                    <span className="bg-danger">Destacado</span>
                                                )}
                                                {plan.featureText.topRated && (
                                                    <span className="bg-warning">Mejor calificado</span>
                                                )}
                                            </div>

                                            {/* Features */}
                                            <div className="listing-details-group">
                                                <ul>
                                                    {plan.vehicle.features.slice(0, 3).map((feature, index) => (
                                                        <li key={index}>
                                                            <span>
                                                                <ImageWithBasePath
                                                                    src={feature.icon}
                                                                    alt={feature.label}
                                                                />
                                                            </span>
                                                            <p>{feature.label}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <ul>
                                                    {plan.vehicle.features.slice(3).map((feature, index) => (
                                                        <li key={index}>
                                                            <span>
                                                                <ImageWithBasePath
                                                                    src={feature.icon}
                                                                    alt={feature.label}
                                                                />
                                                            </span>
                                                            <p>{feature.label}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            {/* Price and Location */}
                                            <div className="listing-location-details">
                                                {/* <div className="listing-price">
                          <span>Ubicación:</span> {car.location}
                        </div> */}
                                                <div className="listing-price">
                                                    <h6>Precio : ${plan.vehicle.price.toLocaleString()}</h6>
                                                    <p>
                                                        Bono Saldo Virtual: $
                                                        {plan.virtualBalanceBonus.toLocaleString()}
                                                    </p>
                                                    <p>
                                                        Bono  cada Referido Exitoso: $
                                                        {plan.referralBonuses.toLocaleString()}
                                                    </p>
                                                </div>
                                            </div>

                                            {/* Action Button */}
                                            <div className="listing-button">
                                                <Link to={handleListingDetails(plan)} className="btn btn-order">
                                                    <span>
                                                        <i className="feather icon-calendar me-2" />
                                                    </span>
                                                    Seleccionar Plan
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Pagination */}
                    <div className="col-12">
                        <div className="pagination">
                            {/* Implementa tu componente de paginación aquí */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CarGridView;
