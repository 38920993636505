import React from "react";
import Slider from "react-slick";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { carTypes } from "../data/cars";

const PopularCarTypes: React.FC = () => {
  

  // Configuración del slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="section popular-car-type">
      <div className="container">
        {/* Título */}
        <div className="section-heading" data-aos="fade-down">
          <h2>Tipos de Autos Más Populares</h2>
          <p>
            Las categorías de autos más populares a nivel mundial por su
            confiabilidad, accesibilidad y características.
          </p>
        </div>
        {/* /Título */}

        {/* Slider de Tipos de Autos */}
        <div className="row">
          <div className="popular-slider-group">
            <div className="popular-cartype-slider">
              <Slider {...sliderSettings} className="service-slider">
                {carTypes.map((type) => (
                  <div key={type.id} className="listing-owl-item">
                    <div className="listing-owl-group">
                      <div className="listing-owl-img">
                        <ImageWithBasePath
                          src={type.image}
                          className="img-fluid"
                          alt={type.name}
                        />
                      </div>
                      <h6>{type.name}</h6>
                      <p>{type.count} Autos</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        {/* Ver Más */}
        <div className="view-all text-center" data-aos="fade-down">
          <a
            href="#"
            className="btn btn-view d-inline-flex align-items-center"
          >
            Ver Todos los Autos{" "}
            <span>
              <i className="feather icon-arrow-right ms-2" />
            </span>
          </a>
        </div>
        {/* /Ver Más */}
      </div>
    </section>
  );
};

export default PopularCarTypes;