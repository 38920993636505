// src/data/hearts.tsx

export interface HeartProps {
  heart1: boolean;
  heart2: boolean;
  heart3: boolean;
  heart4: boolean;
  heart5: boolean;
  heart6: boolean;
  heart7: boolean;
  heart8: boolean;
  heart9: boolean;
}

export const initialHearts: HeartProps = {
  heart1: false,
  heart2: false,
  heart3: false,
  heart4: false,
  heart5: false,
  heart6: false,
  heart7: false,
  heart8: false,
  heart9: false,
};
