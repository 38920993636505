import React from 'react';
import CountUp from "react-countup";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { facts } from "../data/cars";

const FactsByTheNumbers = () => {
  return (
    <section className="section facts-number">
      <div className="facts-left">
        <ImageWithBasePath
          src="assets/img/bg/facts-left.png"
          className="img-fluid"
          alt="datos izquierda"
        />
      </div>
      <div className="facts-right">
        <ImageWithBasePath
          src="assets/img/bg/facts-right.png"
          className="img-fluid"
          alt="datos derecha"
        />
      </div>
      <div className="container">
        {/* Título */}
        <div className="section-heading" data-aos="fade-down">
            <h2 className="title text-white">CuotaPlan en Cifras que Impactan</h2>
            <p className="description text-white">
                Conoce los números detrás del éxito de CuotaPlan. Desde miles de suscriptores hasta millones en premios entregados, nuestras cifras reflejan confianza y resultados.
            </p>
        </div>
        {/* /Título */}
        <div className="counter-group">
          <div className="row">
            {facts.map((fact) => (
              <div
                key={fact.id}
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath src={fact.icon} alt={fact.title} />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={fact.value}
                          duration={fact.duration}
                          separator=","
                        />
                        {fact.suffix}
                        <br />
                      </h4>
                      <p>{fact.title}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FactsByTheNumbers;