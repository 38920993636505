// FILE: utils.tsx

import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';


const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] es la dirección localhost de IPv6.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 son las direcciones IPv4 de localhost.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$/
    )
);

export const initializeAnalytics = () => {
    if (!isLocalhost) {
        ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALITYCS_ID}`);
    }
};

export const usePageTracking = (hitType: string = "pageview") => {
    const location = useLocation();

    useEffect(() => {
        if (!isLocalhost) {
            initializeAnalytics();
            ReactGA.send({ hitType, page: location.pathname });
        }
    }, [location, hitType]);
};


export const registerGAEvent = (
    category: string,
    action: string,
    label?: string,
    value?: number
) => {
    if (!isLocalhost) {
        initializeAnalytics();
        ReactGA.event({
            category,
            action,
            label,
            value, // Útil para métricas numéricas (ej. precio, duración)
        });
    }
};